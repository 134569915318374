<template>
 <div
  class="sticky top-0 px-4 items-center gap-x-4 border-b border-gray-200 shadow-sm flex h-16 flex-shrink-0 bg-white lg:shadow-none lg:border-none"
 >
  <button
   type="button"
   class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
   @click="$emit('sidebar-open', true)"
  >
   <span class="sr-only">Open sidebar</span>
   <Bars3Icon class="h-6 w-6" aria-hidden="true" />
  </button>
  <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />
  <!-- Search bar -->
  <div class="flex flex-1 justify-between px-4 sm:px-6 lg:mx-auto lg:px-8">
   <div class="flex flex-1">
    <form class="flex w-full md:ml-0" action="#" method="GET">
     <label for="search-field" class="sr-only">Search</label>
     <div class="relative w-full text-gray-400 focus-within:text-gray-600">
      <div
       class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
       aria-hidden="true"
      >
       <MagnifyingGlassIcon class="h-5 w-5" aria-hidden="true" />
      </div>
      <input
       v-model="search"
       @input="$emit('update:search', $event.target.value)"
       @focus="(onFocus = true), $emit('on-focus', true)"
       @blur="(onFocus = false), $emit('on-blur', false)"
       id="search-field"
       name="search-field"
       class="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
       :placeholder="[
        !onFocus ? $t('header.search') : $t('header.search') + ' ' + inputPlaceholder,
       ]"
       type="search"
      />
     </div>
    </form>
   </div>
   <div class="ml-4 flex items-center md:ml-6">
    <LanguageSelector />

    <!-- Profile dropdown -->
    <Menu as="div" class="relative ml-3">
     <div>
      <MenuButton
       class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50"
      >
       <span class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
        <span class="uppercase text-xs font-medium leading-none text-white">{{ setAvatar() }}</span>
       </span>
       <span class="ml-3 hidden text-sm font-medium text-gray-700 lg:block"
        ><span class="sr-only">Open user menu for </span> {{ username }}</span
       >
       <ChevronDownIcon
        class="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
        aria-hidden="true"
       />
      </MenuButton>
     </div>
     <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
     >
      <MenuItems
       class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
       <MenuItem v-slot="{ active }">
        <a
         @click="feedback = true"
         href="javascript:void(0)"
         :class="[
          active ? 'bg-gray-100' : '',
          'rounded-md block px-4 py-2 mx-1 text-sm text-gray-700',
         ]"
        >
         {{ $t("header.yourFeedback") }}
        </a>
       </MenuItem>
       <MenuItem v-slot="{ active }">
        <a
         @click="signOut()"
         href="javascript:void(0)"
         :class="[
          active ? 'bg-gray-100' : '',
          'rounded-md block px-4 py-2 mx-1 text-sm text-gray-700',
         ]"
        >
         {{ $t("header.logout") }}
        </a>
       </MenuItem>
      </MenuItems>
     </transition>
    </Menu>
   </div>
  </div>
 </div>
 <main :key="getTitleHeader()">
  <!-- Page header -->
  <div v-if="this.$route.name === 'Dashboard'" class="bg-white">
   <div class="px-4 sm:px-6 lg:mx-auto lg:px-8">
    <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
     <div class="min-w-0 flex-1">
      <!-- Profile -->
      <div class="flex items-center">
       <span
        class="hidden sm:inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-500"
       >
        <span class="text-xl font-medium leading-none text-white uppercase">
         {{ setAvatar() }}
        </span>
       </span>
       <div>
        <div class="flex items-center">
         <h1 class="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
          {{ $t(getTitleHeader()) }}, {{ username }}
         </h1>
        </div>
        <dl class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
         <dt class="sr-only">Company</dt>
         <dd
          v-if="organisation_name && this.role !== 'user'"
          class="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6"
         >
          <BuildingOfficeIcon
           class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
           aria-hidden="true"
          />
          {{ organisation_name }}
         </dd>
        </dl>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>

  <div
   v-else-if="this.$route.name !== 'AccountsDetail' && this.$route.name !== 'EditAccounts'"
   class="flex items-center justify-between px-2 md:px-6 md:pt-4"
  >
   <div class="min-w-0 flex-1">
    <h2
     class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
    >
     {{ pageHeadingTitle && $t(pageHeadingTitle) }}
    </h2>
   </div>
   <div class="mt-4 flex space-x-3 md:ml-4 md:mt-0">
    <button
     v-show="hasSecondaryButton"
     @click="$emit('cta-secondary-emitter')"
     type="button"
     class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    >
     <span class="hidden md:block capitalize-first normal-case">
      {{ secondaryButtonTitle }}
     </span>
     <ArrowUpOnSquareStackIcon class="md:hidden block h-5 w-5 text-gray-600" aria-hidden="true" />
    </button>
    <button
     v-show="this.$route.name !== 'Users' && hasRight"
     @click="$emit('cta-emitter')"
     type="button"
     class="inline-flex items-center rounded-md bg-cyan-600 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
    >
     <span class="hidden md:block normal-case px-2">
      {{ ctaTitleButton }}
     </span>
     <PlusIcon class="md:hidden block h-5 w-5 text-white mx-2" aria-hidden="true" />
    </button>
   </div>
  </div>
 </main>
 <div v-if="feedback || openFeedback">
  <FeedbackForm
   @close-form="(feedback = false), $emit('closeFeedback', false)"
   @activeBanner="$emit('activeBanner')"
   @success="$emit('success', $event)"
   @successMsg="$emit('successMsg', $event)"
  />
 </div>
</template>

<script>
import axios from "axios";
import {
 Disclosure,
 DisclosureButton,
 DisclosurePanel,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
} from "@headlessui/vue";
import {
 Bars3CenterLeftIcon,
 Bars3Icon,
 BellIcon,
 ClockIcon,
 CogIcon,
 CreditCardIcon,
 DocumentChartBarIcon,
 HomeIcon,
 QuestionMarkCircleIcon,
 ScaleIcon,
 ShieldCheckIcon,
 UserGroupIcon,
 XMarkIcon,
} from "@heroicons/vue/24/outline";
import {
 BanknotesIcon,
 BuildingOfficeIcon,
 CheckCircleIcon,
 ChevronDownIcon,
 ChevronRightIcon,
 MagnifyingGlassIcon,
 PlusIcon,
 ArrowUpOnSquareStackIcon,
 ArrowUpOnSquareIcon,
} from "@heroicons/vue/20/solid";
import { mapGetters } from "vuex";

import LanguageSelector from "./LanguageSelector.vue";
import FeedbackForm from "./FeedbackForm.vue";

const logo = localStorage.getItem("logo");
const avatar = localStorage.getItem("avatar");

const accountName = localStorage.getItem("accountName");
const organisation_name = localStorage.getItem("organisation_name");

export default {
 props: [
  "inputPlaceholder",
  "ctaTitleButton",
  "hasRight",
  "hasSecondaryButton",
  "secondaryButtonTitle",
  "pageHeadingTitle",
  "openFeedback",
 ],
 components: {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  BanknotesIcon,
  BuildingOfficeIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  Bars3Icon,
  Bars3CenterLeftIcon,
  BellIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  FeedbackForm,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  PlusIcon,
  ArrowUpOnSquareStackIcon,
  ArrowUpOnSquareIcon,
  ChevronDownIcon,
  LanguageSelector,
 },
 data() {
  return {
   accountName,
   accounts: [],
   avatar,
   email: "",
   impersonatePopup: false,
   logo,
   onFocus: false,
   feedback: false,
   organisation_name,
   quickData: [],
   role: "",
   search: "",
   showNotifications: false,
   notificationsUnread: "",
   notificationsFeed: [
    {
     id: 1,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 2,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 3,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 4,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 5,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 6,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 7,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 8,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
    {
     id: 9,
     title: "New Version !",
     description: "A new version is available, see what's new.",
     isRead: false,
    },
   ],
   accountName,
   username: "",
   role: "",
  };
 },
 methods: {
  getTitleHeader() {
   const date = new Date();
   if (date.getHours() >= 0 && date.getHours() <= 12) {
    return "goodMorning";
   } else if (date.getHours() > 12 && date.getHours() < 17) {
    return "goodAfternoon";
   } else {
    return "goodEvening";
   }
  },
  setAvatar() {
   if (this.username) {
    const name = this.username.split(" ");
    let firstLetter = "";
    let secondLetter = "";
    if (name.length > 1) {
     firstLetter = name[0].charAt(0);
     secondLetter = name[1].charAt(0);
    } else {
     firstLetter = name[0].charAt(0);
     secondLetter = name[0].charAt(1);
    }
    return firstLetter + secondLetter;
   }
  },
  signOut() {
   localStorage.removeItem("token");
   localStorage.removeItem("adminToken");
   localStorage.removeItem("role");
   localStorage.removeItem("user_permissions");

   localStorage.removeItem("name");
   localStorage.removeItem("email");
   localStorage.removeItem("avatar");

   localStorage.removeItem("account");
   localStorage.removeItem("userAccounts");
   localStorage.removeItem("organisation_id");
   localStorage.removeItem("organisation_name");

   this.$store.dispatch("name", "");
   this.$store.dispatch("role", "");
   this.$store.dispatch("user_permissions", "");
   this.$store.dispatch("email", "");

   this.$store.dispatch("user", {});
   this.$store.dispatch("admin", false);
   this.$store.dispatch("token", "");
   this.$store.dispatch("adminToken", "");
   this.$store.dispatch("userAvatar", "");
   this.$store.dispatch("account", "");
   this.$router.push("/");
   location.reload();
  },
  checkAvatar() {
   return this.avatar == "";
  },
  notificationsUnRead() {
   const notificationsUnread = this.notificationsFeed.filter(
    (notification) => notification.isRead === false
   );
   return notificationsUnread;
  },

  checkAvatar() {
   return this.avatar == "";
  },
 },
 computed: {
  ...mapGetters(["search", "user", "account", "adminToken", "token"]),
  filteredAccounts() {
   return this.accounts.filter((account) => {
    return this.account !== account.customerAccount;
   });
  },
 },
 mounted() {
  this.getTitleHeader();
  this.notificationsUnRead();
 },
 created() {
  this.email = this.$cookie.getCookie("email");
  this.username = localStorage.getItem("name");
  this.role = localStorage.getItem("role");
  this.interval = setInterval(() => this.getTitleHeader(), 1800000);
 },
 watch: {},
};
</script>

<style></style>
