<template>
 <div
  class="fixed inset-0 overflow-y-auto z-50"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
 >
  <div class="flex items-center justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
   <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

   <!-- This element is to trick the browser into centering the modal contents. -->
   <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
    >&#8203;</span
   >

   <div
    class="rounded-lg inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6"
   >
    <div>
     <form @submit.prevent="sendFeedback(userAccounts[0].id)" method="POST">
      <div class="space-y-12">
       <div class="border-b border-gray-900/10 pb-12">
        <h2 class="text-xl font-semibold leading-7 text-gray-900">
         {{ $t("yourFeedback") }}
        </h2>
        <p class="text-sm leading-6 text-gray-600">{{ $t("sendYourFeedbackDescription") }}</p>

        <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
         <div class="sm:col-span-6">
          <div class="flex justify-center items-center">
           <span v-for="i in 5" :key="i">
            <svg
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20"
             fill="currentColor"
             :class="[
              hoverIndex >= i || rating >= i ? 'text-yellow-300 cursor-pointer' : 'text-gray-300',
              i === 1 ? 'default' : '',
             ]"
             @mouseover="hoverIndex = i"
             @mouseleave="hoverIndex = 0"
             @click="rating = i"
             class="w-10 h-10"
            >
             <path
              fill-rule="evenodd"
              d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
              clip-rule="evenodd"
             />
            </svg>
           </span>
           <p class="ml-2 text-gray-600">
            {{ $t("valueOutOfFive", { numb: rating }) }}
           </p>
          </div>
         </div>

         <div class="sm:col-span-6 mx-4">
          <label for="subject" class="block text-sm font-medium leading-6 text-gray-900">
           {{ $t("subject") }}
          </label>
          <div class="mt-1">
           <input
            required
            v-model="subject"
            type="text"
            name="subject"
            id="subject"
            minlength="2"
            maxlength="255"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
           />
          </div>
         </div>

         <div class="sm:col-span-6 mx-4">
          <label for="content" class="block text-sm font-medium leading-6 text-gray-900">
           {{ $t("yourMessage") }}
          </label>
          <div class="mt-1">
           <textarea
            required
            v-model="content"
            rows="5"
            name="content"
            id="content"
            minlength="2"
            maxlength="65000"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
           />
          </div>
         </div>
        </div>
       </div>
      </div>
      <div class="mt-6 flex items-center justify-end gap-x-2">
       <button
        type="button"
        @click="$emit('closeForm')"
        class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-semibold text-gray-700 hover:bg-gray-50 focus:outline-none"
       >
        {{ $t("cancel") }}
       </button>
       <button
        type="submit"
        class="rounded-md bg-sky-600 hover:bg-sky-500 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
       >
        {{ $t("ticket.send") }}
       </button>
      </div>
     </form>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";

// import { ExclamationCircleIcon } from "@heroicons/vue/solid";
const userAccounts = JSON.parse(localStorage.getItem("userAccounts"));

export default {
 name: "impersonate",
 props: ["messageToShow", "subMessageToShow", "reload", "error"],
 components: {},
 data() {
  return {
   userAccounts,
   hoverIndex: 0,
   rating: 0,
   content: "",
   subject: "",
  };
 },
 computed: {},
 methods: {
  saveIndex(index) {
   this.rating = index;
  },
  async sendFeedback(id) {
   const options = {
    method: "POST",
    url: `${process.env.VUE_APP_API_URL}/feedback-forms?account_id=${id}`,
    data: {
     rating: this.rating,
     content: this.content,
     subject: this.subject,
    },
    headers: {
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   axios
    .request(options)
    .then((res) => {
     const msg = this.$t("thankYouForYourFeedback");
     this.$emit("closeForm");
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
    })
    .catch((err) => {
     console.error(err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
 },
};
</script>

<style></style>
