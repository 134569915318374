<template>
 <div class="relative">
  <select
   v-on:change="setLanguage($event)"
   v-model="$i18n.locale"
   class="block w-full rounded-md border-0 text-gray-900 focus:ring-2 focus:ring-sky-600 sm:text-sm sm:leading-6"
  >
   <option
    v-for="(lang, i) in $i18n.availableLocales"
    :key="`Lang${i}`"
    :value="lang.toLowerCase()"
   >
    {{ lang.toUpperCase() }}
   </option>
  </select>
  <div class="pointer-events-none absolute inset-y-0 right-9 flex items-center text-sm mr-1">
   <i
    :class="`em em-flag-${$i18n.locale === 'en' ? 'um' : $i18n.locale}`"
    aria-role="presentation"
    :aria-label="`${$i18n.locale === 'en' ? 'em' : $i18n.locale} flag`"
   ></i>
  </div>
 </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

export default {
 name: "LanguageSelector",
 components: {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
 },
 data() {
  return {};
 },
 computed: {},
 methods: {
  setLanguage(event) {
   this.$store.dispatch("language", event.target.value);
   localStorage.setItem("language", event.target.value);
  },
 },
};
</script>

<style></style>
