{
 "addMoreHere": "Add more here",
 "at": "à",
 "allPasswordCondition": "The password must contain at least one special character, one number and one uppercase letter",
 "allTime": "All time",
 "successFailedChart": "Successful messages",
 "successRate": "Success rate",
 "disactivate": "disactivate",
 "homePage": "Home",
 "yourDashboard": "Your dashboard",
 "advanced": "Configuration",
 "accountsUsers": "Account's users",
 "accountsUserDescription": "A list of all the users in your account including their name, email and role.",
 "account": "account",
 "accountId": "Account ID",
 "accountIdDescription": "(required to use the API)",
 "accountDescription": "Choose the account for this campaign",
 "accountInformation": "Account information",
 "accounts": "accounts",
 "accountSectionHeading": "Account {accountName}",
 "activate": "activate",
 "activated": "activated",
 "active": "active",
 "add": "add",
 "addNewNumbers": "Add new numbers...",
 "addNewUser": "Add new user",
 "addUsers": "Add Users",
 "addUsersDescription": "Do you wish to add new users ?",
 "averageCallDuration": "average call duration",
 "averageDropoutTime": "average dropout time",
 "averageWaitingTime": "average waiting time",
 "averageWaitingTimeQueue": "average waiting time in queue",
 "backToLogin": "Back to login page",
 "bannerProduction": "You are now in production.",
 "bannerSandbox": "You are now in the sandbox application.",
 "beganDate": "Began at",
 "billing": "billing",
 "billingActive": "Billing active",
 "billingNonActive": "Billing unactive",
 "billingMode": "Billing mode",
 "byStatusChart": "Active status messages",
 "byDetailedStatusChart": "Messages by detailed status",
 "callQueueNumber": "callqueue number",
 "campaigns_count": "SMS Campaigns",
 "campaign": "campaign",
 "campaignCreatedSuccessfully": "Campagne created successfully",
 "campaignDetail": "Campaign detail and statistics",
 "campaignInformation": "Campaign information",
 "campaignNameDescription": "Type your campaign name",
 "campaignReady": "Campaign ready",
 "campaigns": "campaigns",
 "cancel": "Cancel",
 "content": "content",
 "created": "Total number of messages",
 "confirmPassword": "Confirm password",
 "createdAt": "Created at",
 "createdAtDate": "Created at {date}",
 "credit": "credit",
 "credits": "Credits",
 "creditsNoneLeftAccount": "No credit left",
 "creditsAmounts": "{numb} credits",
 "creditsLeftAccount": "You have {count} credits left",
 "creditConsumptionLimitMonthly": "Monthly credit limit",
 "currentMonth": "Current month",
 "custom": "Custom",
 "delete": "Delete",
 "domain": "Domain",
 "domainAlreadyExist": "This domain already exists",
 "domainSuccessfullySent": "New domain added to the list. Don't forget to save your changes",
 "defaultCountry": "Default country for national numbers",
 "deleteAll": "Delete all",
 "details": "details",
 "didCallers": "DID Callers",
 "disclaimerExceededLengthTitle": "Exceeded character number !",
 "disclaimerExceededLengthParagraph": "Warning, you have exceeded the number of characters allowed. This corresponds to more than 3 SMS messages, which cannot be sent all at once.",
 "deactivate": "deactivate",
 "inactive": "inactive",
 "editUsersAccount": "Edit account's users",
 "invalidCredentials": "Incorrect username or password",
 "errorMsg": "An error has occurred",
 "emailAddress": "Email address",
 "enabledUsers": "Enabled for users",
 "enabledGlobally": "Enabled globally",
 "emailSuccessfullySent": "E-mail added to the list. Don't forget to save your changes",
 "emailAlreadyExist": "This e-mail address already exists",
 "emptyStateTitle": "No {title}",
 "emptyStateSubtitle": "No data available",
 "errorAccountId": "Please provide an account",
 "errorRecipientsLists": "Please provide a recipients list or a recipients",
 "expeditorName": "Expeditor name",
 "expeditorNameDescription": "The name displayed on the recipient's phone when receiving SMS messages from this account",
 "forgotPassword": "Forgot password",
 "general": "General",
 "globalInformation": "Global Information",
 "goodAfternoon": "Good afternoon",
 "goodEvening": "Good evening",
 "goodMorning": "Good morning",
 "completed": "Completed",
 "inProgress": "In progress",
 "inputErrorInvalidEmail": "Please, provide a valid email address",
 "lastMonth": "Previous month",
 "lastWeek": "Last 7 days",
 "lastUpdate": "Last update on",
 "lastUpdateDate": "Last update on {date}",
 "list": "list",
 "listsName": "list name",
 "mail2smsFeature": "The <span class='font-medium'>Mail to SMS</span> feature allows you to send SMS directly from your email inbox. To get started, make sure to enable the feature below and send your emails from a recognized address.<br>Send your emails to <span class='font-semibold'>[number]{'@'}sms.encom.tel</span>. For international sending, make sure to prefix your numbers with two zeros followed by the country code (for example, <span class='font-semibold'>0033612345678{'@'}sms.encom.tel</span>).<br><span class='font-semibold'>Do not include a signature</span>.",
 "mail2smsAccountUsers": "Account users are authorized to send SMS via e-mail",
 "mail2smsEnabled": "Enable Mail to SMS functionality",
 "mail2smsAllowEmails": "Allow the following addresses to send SMS via e-mail",
 "mail2smsAllowDomains": "Allow all addresses of the following domains",
 "manage_users": "Manage users",
 "manage_lists": "Manage lists",
 "marketing": "marketing",
 "marketingDisclaimerPart1": "Be careful ! For all marketing SMS, the message must finish by",
 "marketingDisclaimerPart2": " It's mandatory in order to provide your recipients with a means of unsubscribing and to comply with the obligations of the CNIL",
 "messages_count": "Transactional messages",
 "messages": "messages",
 "name": "Name",
 "newAccount": "new account",
 "newAccountDescription": "Create a sub-account to manage SMS sending and receiving",
 "newCampaign": "new campaign",
 "newList": "new list",
 "newMessage": "new message",
 "newNumbers": "New numbers",
 "newNumbersInformationDescription": "Add the recipients numbers of your SMS here, separated by commas",
 "newOrganisation": "New organisation",
 "newOrganisationDescription": "Add an organisation that can create sub-accounts to manage its own customers",
 "newPassword": "New password",
 "newPasswordCondition": "Choose a new password:",
 "newPasswordDescription": "It must contain one uppercase, one lowercase, one number",
 "newRecipient": "new recipient",
 "no": "no",
 "noItems": "No {itemType}",
 "noItemsDetail": "No data available in this section.",
 "noDataFoundUsers": "No users",
 "noDataFoundUsersDescription": "They are no users for this account",
 "noRecipientYet": "No recipients yet?",
 "noResults": "No results",
 "noResultsForFilter": "No results matching search criteria",
 "notStarted": "not started",
 "number": "number",
 "numberOfAbandonedCalls": "number of abandoned calls",
 "numberOfAnsweredCalls": "number of answered calls",
 "numberOfCalls": "number of calls",
 "numberOfCallsAnsweredByAnother": "number of calls answered by another",
 "numberOfCallsDeterred": "number of calls deterred",
 "numberOfCallsTransferredByAgent": "number of calls transferred by agent",
 "numberOfRepeatedCalls": "number of repeated calls",
 "organisation": "organisation",
 "organisations": "organisations",
 "organisationInformation": "Organisation Information",
 "organisationInformationDescription": "Organisation you related and details about it",
 "organisationRelated": "related organisation",
 "otherInformation": "Other information",
 "otherInformationDescription": "You can add this recipient to a list",
 "ownedBy": "Owned by",
 "ownerEmail": "Owner email",
 "password": "Password",
 "passwordMustMatch": "Passwords must be identical",
 "passwordLength": "The password must be at least 12 characters long",
 "passwordResetSuccessfully": "Please check your email for the link to reset your password",
 "passwordResetMustContain12Characters": "MUST contain at least 12 characters",
 "passwordResetMustContainOneNumber": "MUST contain at least one number",
 "passwordResetMustContainOneSpecialCharacter": "MUST contain at least one special character",
 "passwordResetMustContainOneUppercase": "MUST contain at least one uppercase letter",
 "permissionToEdit": "Edit",
 "permissionToEditDescription": "Add to the user the permission to edit",
 "permissionToManageLists": "Manage recipients lists",
 "permissionToManageListsDescription": "Add to the user the permission to manage recipients lists",
 "permissionToManageUsers": "Manage users",
 "permissionToManageUsersDescription": "Add to the user the permission to manage users",
 "phoneNumber": "Numéro de téléphone",
 "queueDetail": "queue detail",
 "role": "role",
 "ready": "ready",
 "removeUsers": "Remove user | Remove users",
 "removeUserFromAccount": "Remove user from account | Remove users from account",
 "removeUserFromAccountMessage": "Are you sure you want to remove this user from the account? You can add it again later | Are you sure you want to remove these users from the account? You can add them again later",
 "recipient": "recipient",
 "recipientInformation": "Recipient Information",
 "recipientInformationDescription": "Add the recipients of your SMS here. You can also combine this with a recipients list",
 "recipientInformationNewDescription": "Add the informations of the new recipient here",
 "recipientListInformationNewDescription": "Add the informations of the new list here",
 "recipients": "Recipients",
 "recipientsList": "Recipients List",
 "recipientsLists": "Recipients Lists",
 "recipientslistdetail": "Recipients list detail",
 "recipientsListDetailDescription": "Below, the list of contacts present in the <span class='font-semibold text-gray-700'>{name}</span> list.",
 "recipientsListsInformation": "Recipients Information",
 "recipientsListsInformationDescription": "Choose between a recipients list and/or few recipients",
 "remove": "Remove",
 "removeUsersToAccountDescription": "Choose one or more users below if you wish to remove them from the account",
 "reportList": "Report list",
 "resetYourPassword": "Reset your password",
 "defineYourPassword": "Define your password",
 "resetCreatePasswordDetail": "If an account exists for this e-mail, a password reset link will be sent to you.",
 "save": "Save",
 "send": "Send",
 "statisticsData": "statistics data",
 "sendYourFeedbackDescription": "All your comments and suggestions help us to improve your workspace.",
 "single_messages.rate": "Success rate",
 "signin": "Log in",
 "signinToAccount": "Sign in to your account",
 "smsLimit": "{charNumber} characters for {smsNumber} SMS",
 "smsMessageDescription": "Type the content of the SMS",
 "smsType": "Type of SMS",
 "smsTypeDescription": "Choose the type of SMS",
 "statistics": "Statistics",
 "successMsg": "Action successful",
 "subject": "Subject",
 "thankYouForYourFeedback": "Thank you for your feedback. It means a lot to us 👏",
 "typeYourMessageHere": "Type your message here...",
 "timeline": "Timeline",
 "updateAccountUsers": "Update account users",
 "updateAccount": "Update account",
 "updateRecipient": "Update recipient",
 "updateList": "Update list",
 "updateCampaign": "Update SMS Campaign",
 "updatedDate": "Updated",
 "updatedOrganisation": "Organisation updated successfully!",
 "updateOrganisation": "Update organisation",
 "updateUser": "Update user",
 "updateUserDescription": "Enter new informations below",
 "upload": "upload",
 "user": "user",
 "users": "users",
 "usersList": "List of users in the account {accountName}. You can update the users of the account here.",
 "valueOutOfFive": "{numb} out of 5",
 "yourMessage": "Your message",
 "yourFeedback": "Help us improve your SMS service!",
 "yes": "yes",
 "owner": "owner",
 "export": "exporter",
 "import": "importer",
 "importDocument": "import document",
 "importColumnsAutorized": "columns {columns} are autorized",
 "login": {
  "welcome": "Welcome",
  "newPassword": "New password",
  "forgotPassword": "Forgot password",
  "emailAddress": "Email address",
  "password": "Password",
  "confirmPassword": "Confirm password",
  "passwordCreated": "Password successfully created",
  "signin": "Sign in"
 },
 "expertStatisticsNavigation": {
  "advancedStatistics": "Advanced Statistics",
  "origins": "Origins",
  "originsTop10": "Origins Top 10",
  "hourlyStatistics": "Hourly Statistics",
  "groups": "Groups",
  "answeredUnansweredCalls": "Answered & Unanswered"
 },
 "pageNotFound": "Sorry, we couldn't find the page you're looking for...",
 "goBackHome": "Go back home",
 "s": {
  "statistics": "Statistics",
  "invoices": "Invoices",
  "tickets": "Tickets",
  "settings": "Settings",
  "subMenu": { "financial": "Financial", "callQueue": "Call Queues" },
  "specialNavigation": {
   "expertService3CX": "Expert Service 3CX",
   "contact": "Smart Routing",
   "georouting": "Geographical Routing",
   "callqueuegroup": "Call Queues Groups",
   "callqueue": "Call Queues"
  }
 },
 "header": {
  "search": "Search",
  "searchTicketPlaceHolder": "Subject, created date or updated date",
  "searchInvoicePlaceHolder": "Invoice, date or total",
  "searchContactPlaceHolder": "Company, name, code or numbers",
  "hello": "Hello",
  "activeAccount": "Active Account",
  "accountName": "Account name",
  "account": "Account",
  "invoices": "Invoices",
  "openTicket": "New Ticket",
  "yourProfile": "Your Profile",
  "settings": "Settings",
  "logout": "Sign out",
  "yourFeedback": "Leave your feedback",
  "feedback": "Feedback",
  "filter": "Filters",
  "impersonate": "Impersonate",
  "leaveImpersonate": "Leave Impersonate"
 },
 "notificationBanner": {
  "title": "Your application has new features! Open to see more.",
  "learnMoreButton": "Learn more"
 },
 "impersonate": { "popupTitle": "Enter User email to Impersonate" },
 "chartTable": {
  "callType": "Call Type",
  "count": "Count",
  "duration": "Duration",
  "avgPerCall": "Avg per Call",
  "landline": "Landline",
  "mobile": "Mobile",
  "special": "Special",
  "callCount": "Call count",
  "callDuration": "Call duration",
  "fileNumb": "File number",
  "period": "Period",
  "choosePeriod": "Choose period",
  "chooseResource": "Choose resource",
  "resourceNumb": "Resource number",
  "chart": "Charts",
  "data": {
   "incoming": "Incoming",
   "outgoing": "Outgoing",
   "answered": "Answered",
   "unanswered": "Unanswered"
  }
 },
 "dashboards": {
  "title": "Tableau de bord",
  "day": "day",
  "fullDay": "Full day",
  "week": "week",
  "month": "month",
  "concatenate": "Consolidate data",
  "concatenateTitle": "Consolidated data for elements:",
  "chartHeaderTitle": "Statistics for period",
  "searchExtension": "Search extensions",
  "searchQueue": "Search queue",
  "custom": "custom",
  "monthly": "Monthly",
  "daily": "Daily",
  "weekly": "Weekly",
  "hourly": "Hourly",
  "monthlyTitle": "Monthly Dashboard of",
  "monthlyDescription": "Get monthly data of",
  "dailyTitle": "Daily Dashboard of",
  "dailyDescription": "Get daily data of",
  "hourlyTitle": "Hourly Dashboard of",
  "hourlyDescription": "Get hourly data of",
  "donutTitle": "Daily Answered & Unanswered Calls Count",
  "stackedColumnCategories": {
   "incomingCount": "Incoming Count",
   "outgoingCount": "Outgoing Count",
   "answeredCount": "Answered Count",
   "unansweredCount": "Unanswered Count",
   "incomingDuration": "Incoming Duration",
   "outgoingDuration": "Outgoing Duration",
   "answeredDuration": "Answered Duration"
  },
  "error": {
   "noDataFoundTitle": "No Data Found",
   "noDataFoundDescription": "The data for this period was not found or don't exist yet, we're sorry for the inconvenience."
  },
  "selectAll": "Select all",
  "clearAll": "Unselect all",
  "clear": "clear"
 },
 "warning": {
  "noDataFoundTitle": "No Data Found",
  "noResultFoundTitle": "No results found",
  "noResultFoundDescription": "Try adjusting your result of filtering otherwise.",
  "noDataFoundDocumentTitle": "No Documents",
  "noDataFoundDocumentDescription": "You don't have any documents. Contact your line manager if it's not supposed to happen.",
  "noDataFoundContactTitle": "No Contacts",
  "noDataFoundContactDescription": "Get started by creating a new contact or by uploading a contacts list.",
  "noDataFoundCtiTitle": "No CTI",
  "noDataFoundCtiDescription": "Get started by creating a new CTI configuration.",
  "noDataFoundRoutingTitle": "No Geographic Routing",
  "noDataFoundRoutingDescription": "Get started by creating a new geographic routing.",
  "noDataFoundCallQueueTitle": "No Callqueues",
  "noDataFoundCallQueueDescription": "Get started by creating a new callqueue.",
  "noDataFoundCallQueueGroupTitle": "No Callqueue Groups",
  "noDataFoundCallQueueGroupDescription": "Get started by creating a new callqueue group.",
  "noDataFoundTimeSlotDescription": "No time slot created yet. Please add one by clicking on 'Add New Time Slot' button.",
  "noDataFoundExceptionDescription": "No time slot created yet. Please add one by clicking on one of the 'Add Exception' buttons.",
  "noDataFoundSMSListTitle": "No SMS Lists",
  "noDataFoundSMSListDescription": "Get started by creating a new SMS list.",
  "noDataFoundElementGroup": "Get started by creating a new group.",
  "noDataFoundDashboardTitle": "No Dashboard",
  "noDataFoundDashboardDescription": "Get started by selected elements and period."
 },
 "pbxExtension": {
  "selectData": "Select Data",
  "selectPeriod": "Select Period"
 },
 "home": {
  "companies": "Companies"
 },
 "ticket": {
  "ticketForm": "Ticket Form",
  "typeDown": "Type down your questions/problems below",
  "category": "Category",
  "subCategory": "Sub Category",
  "selectProblem": "Select Problem",
  "subject": "Subject",
  "status": "status",
  "resolution": "Estimate resolution date",
  "priority": "priority",
  "emailSubject": "Ticket subject",
  "body": "Body",
  "typeAbove": "Type the problem above",
  "send": "Send",
  "cancel": "Cancel",
  "reply": "Reply",
  "writeReply": "Write your reply for the ticket",
  "concerns": "Concerns"
 },
 "invoiceTable": {
  "invoiceId": "Invoice ID",
  "invoiceNumb": "Invoice Number",
  "totalWithoutTax": "Total Without Tax",
  "totalWithTax": "Total With Tax",
  "date": "Date",
  "searchId": "Search ID",
  "searchNumb": "Search number",
  "searchMonth": "MM",
  "searchYear": "YYYY",
  "searchAmount": "Search amount"
 },
 "ticketTable": {
  "subject": "Ticket",
  "status": "Status",
  "priority": "priority",
  "createdDate": "Date",
  "createdAt": "Created at",
  "updatedDate": "Updated",
  "actions": "Actions",
  "reopenTicket": "Reopen",
  "closeTicket": "Close",
  "selectRows": "Select rows",
  "showAll": "Show all",
  "previous": "Previous",
  "next": "Next",
  "rows": "rows",
  "confirmationCloseMsg": "Are you sure you want to close the ticket?",
  "confirmationOpenMsg": "Are you sure you want to reopen the ticket?",
  "closeMsg": "Ticket closed successfully",
  "openMsg": "Ticket opened successfully",
  "successMsg": "Action successful",
  "dialogTitleClose": "Close ticket",
  "dialogTitleOpen": "Open ticket",
  "showing": "Showing",
  "to": "to",
  "of": "of",
  "results": "results",
  "searchStatus": "Search status",
  "searchDate": "DD/MM/YYYY",
  "ticketList": "Tickets List"
 },
 "documentTable": {
  "allDocuments": "All documents",
  "yourDocuments": "Your documents"
 },
 "callqueue": {
  "buttonTitle": "new call queue",
  "buttonTitleGroup": "new call queue group",
  "newFormTitle": "Call queue form",
  "newFormTitleGroup": "Call queue group form",
  "updateException": "Update exception",
  "updateTimeSlot": "Update call queue group",
  "updateFormTitle": "Update call queue",
  "updateFormTitleGroup": "Update call queue group",
  "formSubTitle": "Add new call queue information below.",
  "formSubTitleGroup": "Add new call queue group information below.",
  "subTitle": "Update by completed informations below"
 },
 "callqueueTable": {
  "name": "name",
  "number": "number",
  "weekdayAndDate": "week days or date",
  "weekday": "Week days",
  "specificDate": "Specific date",
  "timeslot": "time slot",
  "exception": "exceptions",
  "reference": "reference",
  "addTimeSlot": "add time slot",
  "addException": "add exception",
  "addExceptionTwelveMonths": "Add bank holidays for 12 next months",
  "formTimeSlotDescription": "Please select a day or day range and the time slots associated",
  "formExceptionDescription": "Please add a callqueue exception by selecting a specific day",
  "formExceptionTwelveMonthsDescription": "Here the next twelve bank holidays for France",
  "selectAllDays": "All week days",
  "inputSelectDays": "Select mutiple days by using",
  "or": "or",
  "day": "day",
  "dateSelected": "Date Selected",
  "daySelected": "Day Selected",
  "start": "start",
  "end": "end",
  "checkStart": "The start can't be between an existed time slot",
  "checkEnd": "The end can't be between an existed time slot",
  "checkBetween": "This timeslot make conflit with existed one",
  "pickedStartBigger": "The start can't be after or the same than the end",
  "newExceptionAdded": "New exception date picked",
  "newExceptionError": "Sorry, there is an error with your selected date",
  "newExceptionExist": "Exception date already exist",
  "selectExceptionError": "Select an exception date in the calendar before to continue",
  "destinationDescription": "You can specify a destination here. The API returns information about the opening or closing of the call queue according to the schedule. Do not specify a destination here if your call flow calls the API later to route the call (geographic or intelligent routing...)",
  "nextFrenchHolidays": "French Bank Holidays over 12 Months",
  "addFrenchHolidaysWarning": "You will automatically add exceptions for all the dates specified above",
  "continue": "Do you wish to continue ?"
 },
 "weekdays": {
  "mon": "monday",
  "tue": "tuesday",
  "wed": "wednesday",
  "thu": "thurday",
  "fri": "friday",
  "sat": "saturday",
  "sun": "sunday"
 },
 "dialog": {
  "confirm": "Confirm",
  "goBack": "Go Back"
 },
 "profile": {
  "userInfo": "User Information",
  "personalDetails": "Personal details",
  "fullName": "Full name",
  "apiToken": "Api Token",
  "email": "Email address",
  "activeAccount": "Active Account",
  "accounts": "Accounts"
 },
 "contacts": {
  "searchAccount": "Search account",
  "searchCompany": "Search company",
  "searchName": "Search name",
  "code": "Code",
  "searchNumber": "Search number",
  "contactAdded": "Contact added successfully",
  "contactDeleted": "Contact deleted successfully",
  "addContact": "new contact",
  "uploadContact": "upload contacts",
  "companyName": "Company",
  "identificationCode": "Client code identification",
  "firstName": "First name",
  "lastName": "Last name",
  "vip": "VIP",
  "onCall": "On-call contact"
 },
 "pbx": {
  "newGroup": "New group",
  "extensionNewGroupForm": "Extension group form",
  "queueNewGroupForm": "Queue group form",
  "extensionFormSubtittle": "Add new extension group information below",
  "queueFormSubtittle": "Add new queue group information below",
  "more": "more elements...",
  "and": "and"
 },
 "pbxReport": {
  "shareTitle": "Share a report",
  "shareMessage": "You want to share the report with the following settings",
  "shareScheduleTitle": "Share a scheduled report",
  "shareScheduleMessage": "You want to share a scheduled report with the following settings",
  "content": "Please add the recipients to complete the action. If no email is provided, the report will be send to you.",
  "inputError": "Provide a valid email address or one not listed below.",
  "nicknamePlaceholder": "Write report name here",
  "successMsg": "Report sent successfully"
 },
 "billingModes": {
  "prepaid": "prepaid",
  "postpaid": "postpaid",
  "inherit": "inherited from organisation"
 },
 "roles": {
  "admin": "Administrateur",
  "manager": "Manager",
  "user": "Customer"
 },
 "byStatus": {
  "succeeded": "Succeeded",
  "pending": "Pending",
  "failed": "Failed"
 },
 "passwords": {
  "user": "Unknown email address. Please check the provided email address try again.",
  "token": "Request expired, please generate a new one.",
  "reset": "Your password has been changed.",
  "sent": "Password reset instructions have been sent to your email.",
  "throttled": "Request limit per minute reached, please try again in a few moments."
 }
}
