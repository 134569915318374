export default {
  data() {
    return { errorStatus: "", errorStatusText: "", errorDataResponse: "" };
  },
  methods: {
    errorHandling(err) {
      console.error(err.response);
      if (err.response.status == 401 || err.response.status == 403) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("name");
        localStorage.removeItem("role");
        this.$store.dispatch("token", null);
        this.$store.dispatch("email", null);
        this.$store.dispatch("name", null);
        this.$store.dispatch("role", null);
        this.$router.push("/login");
      } else {
        this.errorStatus = err.response.status;
        this.errorStatusText = err.response.statusText;
        if (err.response.data.response) {
          this.errorDataResponse = err.response.data.response;
        } else if (err.response.data.message) {
          this.errorDataResponse = err.response.data.message;
        }
      }
    },
  },
};
