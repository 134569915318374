import { createI18n } from "vue-i18n";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
 const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
 const messages = {};
 locales.keys().forEach((key) => {
  const matched = key.match(/([A-Za-z0-9-_]+)\./i);
  if (matched && matched.length > 1) {
   const locale = matched[1];
   messages[locale] = locales(key).default;
  }
 });
 return messages;
}
const datetimeFormats = {
 en: {
  time: {
   hour: "numeric",
   minute: "numeric",
   second: "numeric",
   hour12: false,
  },
  shorter: {
   year: "numeric",
   month: "numeric",
   day: "numeric",
  },
  short: {
   year: "numeric",
   month: "short",
   day: "numeric",
  },
  medium: {
   year: "numeric",
   month: "short",
   day: "numeric",
   weekday: "short",
  },
  longNumb: {
   year: "numeric",
   month: "short",
   day: "numeric",
   hour: "numeric",
   minute: "numeric",
   hour12: true,
  },
  long: {
   year: "numeric",
   month: "short",
   day: "numeric",
   weekday: "short",
   hour: "numeric",
   minute: "numeric",
   hour12: true,
  },
  longest: {
   year: "numeric",
   month: "short",
   day: "numeric",
   weekday: "short",
   hour: "numeric",
   minute: "numeric",
   second: "numeric",
   hour12: true,
  },
 },
 fr: {
  time: {
   hour: "numeric",
   minute: "numeric",
   second: "numeric",
   hour12: false,
  },
  shorter: {
   year: "numeric",
   month: "numeric",
   day: "numeric",
  },
  short: {
   year: "numeric",
   month: "short",
   day: "numeric",
  },
  medium: {
   year: "numeric",
   month: "short",
   day: "numeric",
   weekday: "short",
  },
  longNumb: {
   year: "numeric",
   month: "short",
   day: "numeric",
   hour: "numeric",
   minute: "numeric",
   hour12: false,
  },
  long: {
   year: "numeric",
   month: "short",
   day: "numeric",
   weekday: "short",
   hour: "numeric",
   minute: "numeric",
   hour12: false,
  },
 },
};

export default createI18n({
 locale: localStorage.getItem("language") || process.env.VUE_APP_I18N_LOCALE || "en",
 fallbackLocale:
  localStorage.getItem("language") || process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
 messages: loadLocaleMessages(),
 datetimeFormats: datetimeFormats,
});
