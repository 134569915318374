import axios from "axios";
export default {
 data() {
  return { storeUserData_accounts: [] };
 },
 methods: {
  storeUserData(email, pwd) {
   const options = {
    method: "POST",
    url: `${process.env.VUE_APP_API_URL}/login`,
    headers: { "Content-Type": "application/json" },
    data: { email: email, password: pwd },
   };

   axios
    .request(options)
    .then((res) => {
     this.storeUserData_accounts = res.data.user.accounts;
    })
    .catch((error) => {
     console.error(error);
    });
  },
 },
};
