import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
 {
  path: "/",
  name: "Home",
  component: () => import("../views/Home.vue"),
  meta: {
   requiresNotLogged: true,
  },
 },
 {
  path: "/login",
  name: "Login",
  component: () => import("../views/Login.vue"),
  meta: {
   requiresNotLogged: true,
  },
 },
 {
  path: "/users/:userId/welcome",
  name: "WelcomeUser",
  props: true,
  component: () => import("../views/WelcomeUser.vue"),
  meta: {
   requiresNotLogged: true,
  },
 },

 {
  path: "/dashboard",
  name: "Dashboard",
  component: () => import("../views/Dashboard.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/messages",
  name: "Messages",
  component: () => import("../views/SMS.vue"),
  meta: {
   requiresAuth: true,
  },
 },

 {
  path: "/messages/:id",
  name: "CampaignDetail",
  props: true,
  component: () => import("../views/CampaignDetail.vue"),
  meta: {
   requiresAuth: true,
  },
 },

 /*  {
  path: "/profile",
  name: "Profile",
  component: () => import("../views/Profile.vue"),
  meta: {
   requiresAuth: true,
  },
 }, */
 /* {
  path: "/users",
  name: "Users",
  component: () => import("../views/Users.vue"),
  meta: {
   requiresAuth: true,
  },
 }, */
 {
  path: "/organisations",
  name: "Organisations",
  component: () => import("../views/Organisations.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/accounts",
  name: "Accounts",
  component: () => import("../views/Accounts.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/accounts/:id",
  name: "AccountsDetail",
  component: () => import("../views/AccountsDetail.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/accounts/:id/edit",
  name: "EditAccounts",
  component: () => import("../views/EditAccounts.vue"),
  meta: {
   requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
   const role = store.state.role;

   if (role === "admin") {
    next();
   } else {
    const permissions = store.state.user_permissions;

    if (permissions.permissions.edit) {
     next();
    } else {
     next({ name: "Accounts" });
    }
   }
  },
 },
 {
  path: "/accounts/:id/users",
  name: "EditAccountsUsers",
  component: () => import("../views/EditAccountsUsers.vue"),
  meta: {
   requiresAuth: true,
  },
  beforeEnter: (to, from, next) => {
   const role = store.state.role;

   if (role === "admin") {
    next();
   } else {
    const permissions = store.state.user_permissions;
    if (permissions.permissions.manage_users) {
     next();
    } else {
     next({ name: "Accounts" });
    }
   }
  },
 },

 {
  path: "/recipients/:activeTab",
  name: "Recipients",
  props: true,
  component: () => import("../views/Recipients.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/recipients/list/:id",
  name: "RecipientsListDetail",
  props: true,
  component: () => import("../views/RecipientsListDetail.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/recipients/list/:id/edit",
  name: "EditRecipientsListUsers",
  props: true,
  component: () => import("../components/EditRecipientsListUsers.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/reset-password",
  name: "ResetPassword",
  component: () => import("../views/ResetPassword.vue"),
  props: (route) => ({ passwordToken: route.query.token }),
  meta: {
   requiresNotLogged: true,
  },
 },

 /*  {
    path: "/changelog",
    name: "Changelog",
    component: () => import("../views/Changelog.vue"),
    meta: {
      requiresAuth: true,
    },
  }, */

 {
  path: "/:pathMatch(.*)*",
  name: "404",
  component: () => import("../components/Error404.vue"),
  meta: {
   requiresAuth: true,
  },
 },
];

const router = createRouter({
 history: createWebHistory(process.env.BASE_URL),
 routes,
});

router.beforeEach((to, from, next) => {
 if (to.matched.some((record) => record.meta.requiresAuth)) {
  if (store.state.token) {
   next();
  } else {
   next({ name: "Login" });
  }
 } else if (to.matched.some((record) => record.meta.requiresNotLogged)) {
  if (!store.state.token) {
   next();
  } else {
   next({ name: "Dashboard" });
  }
 } else {
  next();
 }
});

router.beforeResolve((to, from, next) => {
 if (to.matched.some((record) => record.meta.requiresAuth)) {
  if (store.state.token) {
   next();
  } else {
   next({ name: "Login" });
  }
 } else {
  next();
 }
});

export default router;
