import { createStore } from "vuex";
import axios from "axios";

export default createStore({
 state: {
  storeUserAccounts: [],
  user_permissions: localStorage.getItem("user_permissions")
   ? JSON.parse(localStorage.getItem("user_permissions"))
   : {},
  showBanner: false,
  search: false,
  user: {},
  user_id: localStorage.getItem("user_id") ? localStorage.getItem("user_id") : null,
  avatar: localStorage.getItem("avatar") || "",
  token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
  adminToken: localStorage.getItem("adminToken") || null,
  sidebar: false,
  account: localStorage.getItem("account") || null,
  language: localStorage.getItem("language") || "fr",
  logo: localStorage.getItem("logo") || null,
  role: localStorage.getItem("role") || "",
  name: localStorage.getItem("name") || "John Doe",
  email: localStorage.getItem("email") || "johndoe@example.com",
  pbxElements: undefined,
  pbxTimeSelected: null,
  pbxTimeSelectedEnd: null,
  buttonSelector: null,
  hostName: localStorage.getItem("hostName") || null,
  hostContext: localStorage.getItem("hostContext") || 0,
  hostTimeZone: localStorage.getItem("hostTimeZone") || 0,
 },
 mutations: {
  user_permissions(state, user_permissions) {
   state.user_permissions = user_permissions;
  },
  user_id(state, user_id) {
   state.user_id = user_id;
  },
  STORE_USER_ACCOUNTS(state, accounts) {
   state.storeUserAccounts = accounts;
  },
  showBanner(state, showBanner) {
   state.showBanner = showBanner;
  },
  user(state, user) {
   state.user = user;
  },
  token(state, token) {
   state.token = token;
  },
  name(state, name) {
   state.name = name;
  },
  role(state, role) {
   state.role = role;
  },
  email(state, email) {
   state.email = email;
  },
  adminToken(state, adminToken) {
   state.adminToken = adminToken;
  },
  avatar(state, avatar) {
   state.avatar = avatar;
  },
  account(state, account) {
   state.account = account;
  },
  language(state, language) {
   state.language = language;
  },
  search(state, search) {
   state.search = search;
  },
  logo(state, logo) {
   state.logo = logo;
  },
  pbxElements(state, pbxElements) {
   state.pbxElements = pbxElements;
  },
  pbxTimeSelected(state, pbxTimeSelected) {
   state.pbxTimeSelected = pbxTimeSelected;
  },
  pbxTimeSelectedEnd(state, pbxTimeSelectedEnd) {
   state.pbxTimeSelectedEnd = pbxTimeSelectedEnd;
  },
  buttonSelector(state, buttonSelector) {
   state.buttonSelector = buttonSelector;
  },
  hostName(state, hostName) {
   state.hostName = hostName;
  },
  hostContext(state, hostContext) {
   state.hostContext = hostContext;
  },
  hostTimeZone(state, hostTimeZone) {
   state.hostTimeZone = hostTimeZone;
  },
 },
 actions: {
  async fetchPermissionsData({ commit, rootState }) {
   try {
    const token = rootState.token;
    if (token) {
     const res = await axios.get(`${process.env.VUE_APP_API_URL}/users/${rootState.user_id}`);
     const permissions = res.data.accounts[0].pivot;
     commit("user_permissions", permissions);
     localStorage.setItem("user_permissions", JSON.stringify(permissions));
    }
   } catch (err) {
    console.error(err);
   }
  },
  user_permissions({ commit }, user_permissions) {
   commit("user_permissions", user_permissions);
  },
  user_id(context, user_id) {
   context.commit("user_id", user_id);
  },
  storeUserAccounts({ commit }, accounts) {
   commit("STORE_USER_ACCOUNTS", accounts);
  },
  showBanner(context, showBanner) {
   context.commit("showBanner", showBanner);
  },
  user(context, user) {
   context.commit("user", user);
  },
  token(context, token) {
   context.commit("token", token);
  },
  name(context, name) {
   context.commit("name", name);
  },
  role(context, role) {
   context.commit("role", role);
  },
  email(context, email) {
   context.commit("email", email);
  },
  adminToken(context, adminToken) {
   context.commit("adminToken", adminToken);
  },
  userAvatar(context, avatar) {
   context.commit("avatar", avatar);
  },
  account(context, account) {
   context.commit("account", account);
  },
  language(context, language) {
   context.commit("language", language);
  },
  search(context, search) {
   context.commit("search", search);
  },
  logo(context, logo) {
   context.commit("logo", logo);
  },
  pbxElements(context, pbxElements) {
   context.commit("pbxElements", pbxElements);
  },
  pbxTimeSelected(context, pbxTimeSelected) {
   context.commit("pbxTimeSelected", pbxTimeSelected);
  },
  pbxTimeSelectedEnd(context, pbxTimeSelectedEnd) {
   context.commit("pbxTimeSelectedEnd", pbxTimeSelectedEnd);
  },
  buttonSelector(context, buttonSelector) {
   context.commit("buttonSelector", buttonSelector);
  },
  hostName(context, hostName) {
   context.commit("hostName", hostName);
  },
  hostContext(context, hostContext) {
   context.commit("hostContext", hostContext);
  },
  hostTimeZone(context, hostTimeZone) {
   context.commit("hostTimeZone", hostTimeZone);
  },
 },
 getters: {
  getUserPermissions: (state) => {
   return state.user_permissions;
  },
  user_id: (state) => {
   return state.user_id;
  },
  getUserAccounts: (state) => {
   return state.storeUserAccounts;
  },
  showBanner: (state) => {
   return state.showBanner;
  },
  user: (state) => {
   return state.user;
  },
  token: (state) => {
   return state.token;
  },
  name: (state) => {
   return state.name;
  },
  role: (state) => {
   return state.role;
  },
  email: (state) => {
   return state.email;
  },
  adminToken: (state) => {
   return state.adminToken;
  },
  avatar: (state) => {
   return state.user;
  },
  account: (state) => {
   return state.account;
  },
  language: (state) => {
   return state.language;
  },
  search: (state) => {
   return state.search;
  },
  logo: (state) => {
   return state.logo;
  },
  pbxElements: (state) => {
   return state.pbxElements;
  },
  pbxTimeSelected: (state) => {
   return state.pbxTimeSelected;
  },
  pbxTimeSelectedEnd: (state) => {
   return state.pbxTimeSelectedEnd;
  },
  buttonSelector: (state) => {
   return state.buttonSelector;
  },
  hostName: (state) => {
   return state.hostName;
  },
  hostContext: (state) => {
   return state.hostContext;
  },
  hostTimeZone: (state) => {
   return state.hostTimeZone;
  },
 },
});
