<template>
 <!-- Global notification live region, render this permanently at the end of the document -->
 <div
  aria-live="assertive"
  class="z-50 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
 >
  <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
   <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
   <transition
    enter-active-class="transform ease-out duration-300 transition"
    enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-6"
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
    leave-active-class="transform ease-in duration-300 transition"
    leave-from-class="translate-y-2 opacity-100 sm:translate-y-0 sm:translate-x-0"
    leave-to-class="translate-y-0 opacity-0 sm:translate-x-6"
   >
    <div
     v-show="active && timer"
     class="pointer-events-auto w-full max-w-md overflow-hidden rounded-lg bg-white shadow-xl ring-1 ring-black ring-opacity-5"
    >
     <div class="p-4">
      <div class="flex items-start">
       <div v-if="isSuccess" class="flex-shrink-0">
        <CheckCircleIcon class="h-6 w-6 text-emerald-500" aria-hidden="true" />
       </div>
       <div v-else class="flex-shrink-0">
        <XCircleIcon class="h-6 w-6 text-red-500" aria-hidden="true" />
       </div>
       <div class="ml-3 w-0 flex-1 pt-0.5">
        <p
         class="text-sm font-semibold"
         :class="{ 'text-emerald-500': isSuccess, 'text-red-500': !isSuccess }"
        >
         {{ isSuccess ? $t("successMsg") : $t("errorMsg") }} !
        </p>

        <p v-if="typeof msg === 'string'" class="mt-1 text-sm text-gray-500">
         {{ msg }}
        </p>

        <li v-else v-for="e in msg" class="mt-1 text-sm text-gray-900 list-none">
         <ul>
          {{
           e
          }}
         </ul>
        </li>
       </div>
       <div class="ml-4 flex flex-shrink-0">
        <button
         type="button"
         @click="$emit('closeBanner')"
         class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
         <span class="sr-only">Close</span>
         <XMarkIcon class="h-5 w-5" aria-hidden="true" />
        </button>
       </div>
      </div>
     </div>
    </div>
   </transition>
  </div>
 </div>
 <!-- 
 <div
  aria-live="assertive"
  class="z-50 fixed inset-x-0 inset-y-10 flex items-start px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
 >
  <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
   
   <div
    v-show="active && timer"
    class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
   >
    <div class="p-4">
     <div class="flex items-start">
      <div v-if="active && isSuccess && timer" class="flex-shrink-0">
       <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
      </div>
      <div v-else-if="active && !isSuccess && timer" class="flex-shrink-0">
       <XCircleIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
      </div>
      <div class="ml-3 w-0 flex-1 pt-0.5">
       <p v-show="!isSuccess" class="text-sm font-medium text-gray-900">Something went wrong!</p>
       <p :class="[!isSuccess ? 'text-gray-500 mt-1' : 'font-medium text-gray-900', 'text-sm']">
        {{ msg }}
       </p>
      </div>
      <div class="ml-4 flex-shrink-0 flex">
       <button
        @click="$emit('closeBanner')"
        class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
       >
        <span class="sr-only">Close</span>
        <XIcon class="h-5 w-5" aria-hidden="true" />
       </button>
      </div>
     </div>
    </div>
   </div>
  </div>
 </div> -->
</template>

<script>
import { CheckCircleIcon, XCircleIcon, XMarkIcon } from "@heroicons/vue/24/outline";

export default {
 props: ["msg", "active", "isSuccess"],
 data() {
  return {
   timer: false,
   show: true,
  };
 },
 components: {
  XMarkIcon,
  XCircleIcon,
  CheckCircleIcon,
 },
 methods: {
  openBanner() {
   this.timer = true;
   let v = this;
   setTimeout(function () {
    v.timer = false;
    v.$emit("bannerTimer");
    v.$forceUpdate();
   }, 3500);
  },
 },
 mounted() {
  this.openBanner();
 },
};
</script>

<style></style>
