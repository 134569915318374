<template>
 <TransitionRoot as="template" :show="mobileMenuOpen">
  <Dialog as="div" class="relative z-50 lg:hidden" @close="mobileMenuOpen = false">
   <TransitionChild
    as="template"
    enter="transition-opacity ease-linear duration-300"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity ease-linear duration-300"
    leave-from="opacity-100"
    leave-to="opacity-0"
   >
    <div class="fixed inset-0 bg-gray-900/80" />
   </TransitionChild>

   <div class="fixed inset-0 flex">
    <TransitionChild
     as="template"
     enter="transition ease-in-out duration-300 transform"
     enter-from="-translate-x-full"
     enter-to="translate-x-0"
     leave="transition ease-in-out duration-300 transform"
     leave-from="translate-x-0"
     leave-to="-translate-x-full"
    >
     <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
      <TransitionChild
       as="template"
       enter="ease-in-out duration-300"
       enter-from="opacity-0"
       enter-to="opacity-100"
       leave="ease-in-out duration-300"
       leave-from="opacity-100"
       leave-to="opacity-0"
      >
       <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
        <button
         type="button"
         class="-m-2.5 p-2.5"
         @click="(mobileMenuOpen = false), $emit('closeSubmenu')"
        >
         <span class="sr-only">Close sidebar</span>
         <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
        </button>
       </div>
      </TransitionChild>
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
       <div class="flex h-16 shrink-0 items-center">
        <img class="h-8 w-auto" src="../assets/encom-logo-cut.png" alt="Welcome" />
       </div>
       <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
         <li>
          <ul role="list" class="-mx-2 space-y-1">
           <li v-for="item in navigation" :key="item.name">
            <a
             :href="item.href"
             v-if="!item.children"
             :class="[
              this.$route.name === item.route
               ? 'bg-gray-100 text-blue-600'
               : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
             ]"
            >
             <component
              :is="item.icon"
              :class="[
               this.$route.name === item.route
                ? 'text-blue-600'
                : 'text-gray-400 group-hover:text-blue-600',
               'h-6 w-6 shrink-0',
              ]"
              aria-hidden="true"
             />
             {{ $t(item.name) }}
            </a>
            <Disclosure as="div" v-else v-slot="{ open }">
             <DisclosureButton
              :class="[
               this.$route.name === item.route ? 'bg-gray-50' : 'hover:bg-gray-50',
               'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700',
              ]"
             >
              <component
               :is="item.icon"
               :class="[
                this.$route.name === item.route
                 ? 'text-blue-600'
                 : 'text-gray-400 group-hover:text-blue-600',
                'h-6 w-6 shrink-0',
               ]"
               aria-hidden="true"
              />

              {{ $t(item.name) }}

              <ChevronRightIcon
               :class="[open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'h-5 w-5 shrink-0']"
               aria-hidden="true"
              />
             </DisclosureButton>
             <DisclosurePanel as="ul" class="mt-1 px-2">
              <li v-for="subItem in item.children" :key="subItem.name">
               <DisclosureButton
                as="a"
                :href="subItem.href"
                :class="[
                 this.$route.params.activeTab === subItem.route
                  ? 'bg-gray-100'
                  : 'hover:bg-gray-50',
                 'block rounded-md py-2 mb-1 pr-2 pl-5 text-xs text-gray-700',
                ]"
               >
                {{ $t(subItem.name) }}
               </DisclosureButton>
              </li>
             </DisclosurePanel>
            </Disclosure>
           </li>
          </ul>
         </li>
         <!--  <li>
          <div class="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
          <ul role="list" class="-mx-2 mt-2 space-y-1">
           <li v-for="team in teams" :key="team.name">
            <a
             :href="team.href"
             :class="[
              team.current
               ? 'bg-gray-50 text-indigo-600'
               : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
             ]"
            >
             <span
              :class="[
               team.current
                ? 'text-indigo-600 border-indigo-600'
                : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
               'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white',
              ]"
              >{{ team.initial }}</span
             >
             <span class="truncate">{{ team.name }}</span>
            </a>
           </li>
          </ul>
         </li> -->
        </ul>
       </nav>
      </div>
     </DialogPanel>
    </TransitionChild>
   </div>
  </Dialog>
 </TransitionRoot>

 <!-- Static sidebar for desktop -->
 <div class="hidden lg:inset-y-0 lg:flex lg:w-52 lg:flex-col">
  <!-- Sidebar component, swap this element with another sidebar if you like -->
  <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
   <div class="flex h-16 shrink-0 items-center">
    <img class="h-7 w-auto" src="../assets/encom_horizontal.png" alt="Welcome" />
   </div>
   <nav class="flex flex-1 flex-col">
    <ul role="list" class="flex flex-1 flex-col gap-y-7">
     <ul role="list" class="-mx-2 space-y-1">
      <li v-for="item in navigation" :key="item.name">
       <a
        v-if="!item.children"
        :href="item.href"
        :class="[
         this.$route.name === item.route
          ? 'bg-gray-100 text-blue-500'
          : 'text-gray-700 hover:text-blue-600 hover:bg-gray-50',
         'group flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700',
        ]"
       >
        <component
         :is="item.icon"
         :class="[
          this.$route.name === item.route
           ? 'text-blue-500'
           : 'text-gray-400 group-hover:text-blue-600',
          'h-6 w-6 shrink-0',
         ]"
         aria-hidden="true"
        />

        {{ $t(item.name) }}
       </a>
       <Disclosure as="div" v-else v-slot="{ open }">
        <DisclosureButton
         :class="[
          this.$route.name === item.route ? 'bg-gray-50' : 'hover:bg-gray-50',
          'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700',
         ]"
         @click="toggleDisclosure"
        >
         <component
          :is="item.icon"
          :class="[
           this.$route.name === item.route
            ? 'text-blue-600'
            : 'text-gray-400 group-hover:text-blue-600',
           'h-6 w-6 shrink-0',
          ]"
          aria-hidden="true"
         />

         {{ $t(item.name) }}

         <ChevronRightIcon
          :class="[
           disclosureOpen ? 'rotate-90 text-gray-500' : 'text-gray-400',
           'h-5 w-5 shrink-0',
          ]"
          aria-hidden="true"
         />
        </DisclosureButton>
        <ul as="ul" class="mt-1 px-2" v-show="disclosureOpen">
         <li v-for="subItem in item.children" :key="subItem.name">
          <DisclosureButton
           as="a"
           :href="subItem.href"
           :class="[
            this.$route.params.activeTab === subItem.route ? 'bg-gray-100' : 'hover:bg-gray-50',
            'block rounded-md py-2 mb-1 pr-2 pl-5 text-xs text-gray-700',
           ]"
          >
           {{ $t(subItem.name) }}
          </DisclosureButton>
         </li>
        </ul>
       </Disclosure>
      </li>
     </ul>
     <!--      <li>
      <div class="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
      <ul role="list" class="-mx-2 mt-2 space-y-1">
       <li v-for="team in teams" :key="team.name">
        <a
         :href="team.href"
         :class="[
          team.current
           ? 'bg-gray-50 text-indigo-600'
           : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
         ]"
        >
         <span
          :class="[
           team.current
            ? 'text-indigo-600 border-indigo-600'
            : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
           'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white',
          ]"
          >{{ team.initial }}</span
         >
         <span class="truncate">{{ team.name }}</span>
        </a>
       </li>
      </ul>
     </li> -->
     <li class="mt-auto pb-5">
      <a
       @click="$emit('openFeedback', true)"
       href="javascript:void(0)"
       class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-sky-600"
      >
       <MegaphoneIcon
        class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-sky-600"
        aria-hidden="true"
       />
       <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-sky-600"
       >
        <path
         d="M13.92 3.845a19.361 19.361 0 01-6.3 1.98C6.765 5.942 5.89 6 5 6a4 4 0 00-.504 7.969 15.974 15.974 0 001.271 3.341c.397.77 1.342 1 2.05.59l.867-.5c.726-.42.94-1.321.588-2.021-.166-.33-.315-.666-.448-1.004 1.8.358 3.511.964 5.096 1.78A17.964 17.964 0 0015 10c0-2.161-.381-4.234-1.08-6.155zM15.243 3.097A19.456 19.456 0 0116.5 10c0 2.431-.445 4.758-1.257 6.904l-.03.077a.75.75 0 001.401.537 20.902 20.902 0 001.312-5.745 1.999 1.999 0 000-3.545 20.902 20.902 0 00-1.312-5.745.75.75 0 00-1.4.537l.029.077z"
        />
       </svg> -->
       {{ $t("header.feedback") }}
      </a>
     </li>
    </ul>
   </nav>
  </div>
 </div>
</template>

<script>
import { ref } from "vue";
import { ChevronRightIcon } from "@heroicons/vue/20/solid";
import { Bars3Icon, XMarkIcon, MegaphoneIcon } from "@heroicons/vue/24/outline";
import {
 Dialog,
 DialogOverlay,
 Disclosure,
 DisclosureButton,
 DisclosurePanel,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";

const sandbox = localStorage.getItem("sandbox");
const logo = localStorage.getItem("logo");
const adminToken = localStorage.getItem("adminToken");

const mockNavigation = [
 { name: "Dashboard", href: "#", current: true },
 {
  name: "Teams",
  current: false,
  children: [
   { name: "Engineering", href: "#" },
   { name: "Human Resources", href: "#" },
   { name: "Customer Success", href: "#" },
  ],
 },
 {
  name: "Projects",
  current: false,
  children: [
   { name: "GraphQL API", href: "#" },
   { name: "iOS App", href: "#" },
   { name: "Android App", href: "#" },
   { name: "New Customer Portal", href: "#" },
  ],
 },
 { name: "Calendar", href: "#", current: false },
 { name: "Documents", href: "#", current: false },
 { name: "Reports", href: "#", current: false },
];
const teams = [
 { id: 1, name: "Heroicons", href: "#", initial: "H", current: false },
 { id: 2, name: "Tailwind Labs", href: "#", initial: "T", current: false },
 { id: 3, name: "Workcation", href: "#", initial: "W", current: false },
];

export default {
 name: "Sidebar",
 props: [
  "navigation",
  "userNavigation",
  "secondaryNavigation",
  "statisticsAvailableForProduction",
  "hasChildren",
  "openSubmenu",
 ],
 components: {
  MegaphoneIcon,
  Bars3Icon,
  ChevronRightIcon,
  XMarkIcon,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
 },
 data() {
  return {
   mockNavigation,
   teams,
   sandbox,
   mobileMenuOpen: false,
   logo,
   adminToken,
   role: "",
   disclosureOpen: false,
  };
 },
 methods: {
  signOut() {
   localStorage.removeItem("token");
   localStorage.removeItem("adminToken");
   localStorage.removeItem("avatar");
   localStorage.removeItem("account");
   localStorage.removeItem("userAccounts");
   localStorage.removeItem("accountName");
   localStorage.removeItem("organisation_name");
   this.$store.dispatch("user", {});
   this.$store.dispatch("admin", false);
   this.$store.dispatch("token", "");
   this.$store.dispatch("adminToken", "");
   this.$store.dispatch("userAvatar", "");
   this.$store.dispatch("account", "");
   this.$router.push("/login");
   location.reload();
  },
  setAvatar() {
   if (this.username) {
    const name = this.username.split(" ");
    let firstLetter = "";
    let secondLetter = "";
    if (name.length > 1) {
     firstLetter = name[0].charAt(0);
     secondLetter = name[1].charAt(0);
    } else {
     firstLetter = name[0].charAt(0);
     secondLetter = name[0].charAt(1);
    }
    return firstLetter + secondLetter;
   }
  },
  toggleDisclosure() {
   this.disclosureOpen = !this.disclosureOpen;
   sessionStorage.setItem("disclosureOpen", this.disclosureOpen);
  },
 },
 created() {
  this.role = localStorage.getItem("role");
  this.email = this.$cookie.getCookie("email");
  this.username = localStorage.getItem("name");
  const storedValue = sessionStorage.getItem("disclosureOpen");
  if (storedValue !== null) {
   this.disclosureOpen = storedValue === "true";
  }
 },
 watch: {
  openSubmenu: function (val) {
   this.mobileMenuOpen = val;
  },
 },
};
</script>

<style>
.sandbox_view {
 position: absolute;
 bottom: 0;
}
.logout_impersonate_buttons {
 position: relative;
 bottom: 0;
}
</style>
