import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import store from "./store";
import "./assets/tailwind.css";
import axios from "axios";
import i18n from "./i18n";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import "./assets/css/main.css";
import ErrorHandling from "./mixins/ErrorHandling";
import { useCookie, VueCookieNext } from "vue-cookie-next";
import titleMixin from "./mixins/titleMixin";
import storeUserData from "./mixins/storeUserData";
import VueNextSelect from "vue-next-select";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
 faAddressBook,
 faCalendar,
 faCalendarDay,
 faCalendarDays,
 faCalendarWeek,
 faClock,
 faChartPie,
 faChartBar,
 faClipboardList,
 faComment,
 faEnvelope,
 faEnvelopeOpenText,
 faFileInvoiceDollar,
 faFilePdf,
 faFilterCircleXmark,
 faGear,
 faHeadset,
 faPhone,
 faRssSquare,
 faRightFromBracket,
 faRightToBracket,
 faSms,
 faServer,
 faShare,
 faTags,
 faUserTie,
 faWrench,
 faArchive,
 faFolder,
 faUser,
 faBuilding,
 faHouseUser,
 faUserShield,
 faTrash,
 faTrashCan,
 faAt,
 faFileExport,
 faFileImport,
 faHouse,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck, faComments } from "@fortawesome/free-regular-svg-icons";

library.add(
 faAddressBook,
 faCalendar,
 faBuilding,
 faCalendarCheck,
 faCalendarDay,
 faCalendarDays,
 faCalendarWeek,
 faClock,
 faChartPie,
 faChartBar,
 faClipboardList,
 faComment,
 faEnvelope,
 faEnvelopeOpenText,
 faFileInvoiceDollar,
 faFilePdf,
 faFilterCircleXmark,
 faGear,
 faHeadset,
 faHouseUser,
 faPhone,
 faRssSquare,
 faRightFromBracket,
 faRightToBracket,
 faSms,
 faServer,
 faShare,
 faTags,
 faUserTie,
 faUser,
 faUserShield,
 faWrench,
 faArchive,
 faFolder,
 faComments,
 faTrash,
 faTrashCan,
 faAt,
 faFileExport,
 faFileImport,
 faHouse
);

axios.defaults.headers.common["Authorization"] = "Bearer " + store.state.token;
axios.defaults.baseURL = VueCookieNext.getCookie("API");

createApp(App)
 .use(VCalendar, {})
 .use(i18n)
 .use(store)
 .use(router)
 .use(VueApexCharts)
 .use(VueCookieNext)
 .component("font-awesome-icon", FontAwesomeIcon)
 .component("vue-select", VueNextSelect)
 .mixin(titleMixin)
 .mixin(ErrorHandling)
 .mixin(storeUserData)
 .mount("#app");
