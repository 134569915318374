<template>
 <div class="flex bg-gray-100 min_height_app">
  <Sidebar
   @closeTicketDetail="closeTicketDetail = !closeTicketDetail"
   @deactiveHover="sideNavHover = false"
   @openSideNavHover="openSideNavHover($event)"
   :navigation="this.role === 'user' ? userNavigation : navigation"
   :userNavigation="userNavigation"
   :statisticsAvailableForProduction="statisticsAvailableForProduction"
   v-if="
    this.$route.path !== '/login' &&
    this.$route.name !== 'WelcomeUser' &&
    this.$route.name !== 'ResetPassword' &&
    this.$route.name !== '404' &&
    this.$route.name !== 'Home'
   "
   @openNewSidebar="openSide = !openSide"
   @closeSubmenu="openSubmenu = false"
   @open-feedback="openFeedback = $event"
   :openSubmenu="openSubmenu"
  />

  <div class="flex flex-col flex-1 overflow-hidden">
   <!-- Primary column -->
   <NotificationBanner
    v-if="
     this.showBanner &&
     closeBannerKey == false &&
     this.$route.path !== '/login' &&
     this.$route.name !== 'WelcomeUser' &&
     this.$route.name !== 'ResetPassword' &&
     this.$route.name !== '404'
    "
    :key="closeBannerKey"
    @closeBanner="closeBanner"
   />

   <MainHeader
    v-if="
     this.$route.path !== '/login' &&
     this.$route.name !== 'WelcomeUser' &&
     this.$route.name !== 'ResetPassword' &&
     this.$route.name !== '404' &&
     this.$route.name !== 'Home'
    "
    @cta-emitter="ctaEmitter = !ctaEmitter"
    @cta-secondary-emitter="ctaSecondaryEmitter = !ctaSecondaryEmitter"
    @activeBanner="(activeBanner = true), (key = !key)"
    @successMsg="successMsg = $event"
    @success="isSuccess = $event"
    @close-feedback="openFeedback = $event"
    @sidebar-open="openSubmenu = true"
    v-model:search="search"
    :inputPlaceholder="title"
    :page-heading-title="this.$route.name && this.$route.name.toLowerCase()"
    :cta-title-button="ctaTitleButton"
    :hasRight="hasRight"
    :hasSecondaryButton="hasSecondaryButton"
    :secondaryButtonTitle="secondaryButtonTitle"
    :openFeedback="openFeedback"
   />
   <div class="flex items-stretch flex-1 overflow-hidden">
    <main class="flex-1 overflow-y-auto">
     <!-- Primary column -->
     <section
      aria-labelledby="primary-heading"
      class="flex flex-col flex-1 h-full min-w-0 lg:order-last"
     >
      <SuccessBanner
       :key="key"
       :msg="successMsg"
       :active="activeBanner"
       @closeBanner="activeBanner = false"
       :isSuccess="isSuccess"
      />

      <router-view
       :key="$route.fullPath"
       :closeTicketDetail="closeTicketDetail"
       :user_permissions="store_user_permissions"
       :search="search"
       :openForm="ctaEmitter"
       :openSecondaryForm="ctaSecondaryEmitter"
       @title="title = $event"
       @ctaTitleButton="ctaTitleButton = $event"
       @close-cta-emitter="ctaEmitter = false"
       @close-cta-secondary-emitter="ctaSecondaryEmitter = false"
       @has-right="hasRight = $event"
       @cta-secondary="(...args) => handleSecondaryButton(...args)"
       @fetch-permissions-data="
        fetchPermissionsData(),
         this.$router.push({ name: this.$route.name, params: { id: this.$route.params.id } })
       "
       @activeBanner="(activeBanner = true), (key = !key)"
       @successMsg="successMsg = $event"
       @success="isSuccess = $event"
      />
     </section>
    </main>
    <!-- Secondary column (hidden on smaller screens) -->
   </div>
  </div>
  <!-- <div :class="['flex-1 flex flex-col overflow-hidden']">


    </div> -->
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

import MainHeader from "./components/MainHeader.vue";
import Sidebar from "./components/Sidebar.vue";
import SideNav from "./components/SideNav.vue";
import SideNavOff from "./components/SideNavOff.vue";
import SuccessBanner from "./components/SuccessBanner.vue";

import {
 BriefcaseIcon,
 HomeIcon,
 UsersIcon,
 ChatBubbleLeftEllipsisIcon,
 BuildingOfficeIcon,
 BookmarkSquareIcon,
} from "@heroicons/vue/24/outline";

const token = localStorage.getItem("token");
const adminToken = localStorage.getItem("adminToken");
const showBanner = localStorage.getItem("showBanner");

const sandbox = localStorage.getItem("sandbox");
const isLocal = localStorage.getItem("isLocal");
const email = localStorage.getItem("email");

const navigation = [
 {
  name: "homePage",
  href: "/dashboard",
  route: "Dashboard",
  icon: HomeIcon,
  show: true,
  current: false,
 },
 {
  name: "SMS",
  href: "/messages",
  route: "Messages",
  icon: ChatBubbleLeftEllipsisIcon,
  show: true,
  current: false,
 },
 {
  name: "recipients",
  href: "/recipients/list",
  route: "Recipients",
  icon: BookmarkSquareIcon,
  show: true,
  current: false,
  children: [
   { name: "recipientsLists", href: "/recipients/list", route: "list" },
   { name: "recipients", href: "/recipients/recipient", route: "recipient" },
  ],
 },
 /*  {
  name: "users",
  href: `/users`,
  route: "Users",
  icon: UsersIcon,
  show: true,
  current: false,
 }, */
 {
  name: "accounts",
  href: "/accounts",
  route: "Accounts",
  icon: BriefcaseIcon,
  show: true,
  current: false,
 },
 {
  name: "Organisations",
  href: "/organisations",
  route: "Organisations",
  icon: BuildingOfficeIcon,
  show: true,
  current: false,
 },
];
const userNavigation = [
 {
  name: "homePage",
  href: "/dashboard",
  route: "Dashboard",
  icon: HomeIcon,
  show: true,
  current: false,
 },
 {
  name: "SMS",
  href: "/messages",
  route: "Messages",
  icon: ChatBubbleLeftEllipsisIcon,
  show: true,
  current: false,
 },
 {
  name: "recipients",
  href: "/recipients/list",
  route: "Recipients",
  icon: BookmarkSquareIcon,
  show: true,
  current: false,
  children: [
   { name: "recipientsLists", href: "/recipients/list", route: "list" },
   { name: "recipients", href: "/recipients/recipient", route: "recipient" },
  ],
 },
 /*  {
  name: "users",
  href: `/users`,
  route: "Users",
  icon: UsersIcon,
  show: true,
  current: false,
 }, */
 {
  name: "accounts",
  href: "/accounts",
  route: "Accounts",
  icon: BriefcaseIcon,
  show: true,
  current: false,
 },
];

const statisticsAvailableForProduction = process.env.VUE_APP_STATISTICS_PRODUCTION_AVAILABLE;

export default {
 title() {
  return `SMS Marketing Platform`;
  // return `${localStorage.getItem("title")}`;
 },
 components: {
  ChatBubbleLeftEllipsisIcon,
  HomeIcon,
  UsersIcon,
  BookmarkSquareIcon,
  BriefcaseIcon,
  MainHeader,
  Sidebar,
  SideNav,
  SideNavOff,
  SuccessBanner,
 },
 data() {
  return {
   activeBanner: false,
   adminToken,
   closeBannerKey: false,
   closeTicketDetail: false,
   ctaEmitter: false,
   ctaSecondaryEmitter: false,
   ctaTitleButton: "",
   cssPath: "",
   email,
   hasSideNav: false,
   hideNoNav: false,
   hasSecondaryButton: false,
   isLocal,
   isSuccess: true,
   key: false,
   navigation,
   openSide: false,
   openSubmenu: false,
   showSideNav: true,
   showBanner,
   sideHoverNav: "",
   sideNavHover: false,
   statisticsAvailableForProduction,
   secondaryButtonTitle: "",
   sandbox,
   successMsg: "",
   search: "",
   token,
   title: "",
   userNavigation,
   openFeedback: false,
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
  };
 },
 methods: {
  ...mapActions(["fetchPermissionsData"]),

  initIcon(icon) {
   var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
   link.type = "image/x-icon";
   link.rel = "shortcut icon";
   link.href = icon;
   document.getElementsByTagName("head")[0].appendChild(link);
  },
  openSideNavHover(event) {
   this.sideHoverNav = event;
   if (
    this.sideHoverNav === "sidebar.invoices" ||
    this.sideHoverNav === "sidebar.tickets" ||
    this.sideHoverNav === "Documents"
   ) {
    this.sideNavHover = false;
    this.showSideNav = true;
   } else {
    this.sideNavHover = true;
   }
   this.hideNoNav = true;
  },
  showSubmenuTest(event) {
   console.log(event); //to remove
  },

  handleSecondaryButton(...args) {
   this.hasSecondaryButton = args[0];
   this.secondaryButtonTitle = args[1];
  },
 },
 mounted() {
  if (this.role !== "admin") this.fetchPermissionsData();
 },
 created() {
  this.role = localStorage.getItem("role");
 },
 computed: {
  ...mapGetters(["getUserPermissions"]),
  store_user_permissions() {
   return this.getUserPermissions;
  },
 },
};
</script>

<style>
.side_nav_width {
 min-width: 270px;
 overflow: hidden;
}
.side_nav_off_width {
 width: 50px;
 overflow: hidden;
}
</style>
