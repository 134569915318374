{
 "addMoreHere": "Ajoutez-en ici",
 "at": "à",
 "allPasswordCondition": "Le mot de passe doit comporter au moins un caractère spécial, un chiffre et une lettre majuscule",
 "allTime": "Toutes périodes",
 "successFailedChart": "Taux de réussite",
 "byStatusChart": "Messages par statut",
 "byDetailedStatusChart": "Messages par statut détaillé",
 "successRate": "Taux de réussite",
 "disactivate": "désactiver",
 "homePage": "Accueil",
 "yourDashboard": "Votre tableau de bord",
 "addRecipientsCampaign": "Vous pouvez aussi rechercher et ajouter un destinataire, en plus d'une liste",
 "account": "Compte",
 "accountsUsers": "Utilisateurs du compte",
 "accountsUserDescription": "Une liste de tous les utilisateurs de votre compte, y compris leur nom, leur adresse électronique et leur rôle",
 "advanced": "Configuration",
 "accountId": "ID du compte",
 "accountIdApi": "ID du compte<br>(Utilisé par l'API)",
 "accountIdDescription": "(obligatoire pour utiliser l'API)",
 "accountDescription": "Choisissez le compte pour cette campagne",
 "accountInformation": "Information sur le compte",
 "accounts": "Comptes",
 "accountSectionHeading": "Compte {accountName}",
 "activate": "activer",
 "activated": "activé",
 "active": "actif",
 "add": "ajouter",
 "addNewNumbers": "Add new numbers...",
 "addNewUser": "Ajouter un nouvel utilisateur",
 "addRecipients": "Ajouter destinataire",
 "addUsers": "Ajouter utilisateurs",
 "addUsersDescription": "Souhaitez-vous ajouter de nouveaux utilisateurs ?",
 "averageCallDuration": "average call duration",
 "averageDropoutTime": "average dropout time",
 "averageWaitingTime": "average waiting time",
 "averageWaitingTimeQueue": "average waiting time in queue",
 "blacklisted": "Liste noire",
 "backToLogin": "Retour à la page d'accueil",
 "bannerProduction": "Attention, vous êtes en mode production !",
 "bannerSandbox": "Vous êtes en mode Sandbox",
 "beganDate": "Commencé le",
 "billingActive": "Facturation activée",
 "billingNonActive": "Facturation desactivée",
 "billing": "facturation",
 "billingMode": "Méthode de facturation",
 "callQueueNumber": "numéro de file d'appel",
 "campaigns_count": "Campagnes SMS",
 "campaign": "campagne",
 "campaignTitle": "Titre de la campagne",
 "campaignCreatedSuccessfully": "Campagne crée avec succès",
 "campaigndetail": "Détail de la campagne",
 "campaignInformation": "Information sur la campagne",
 "campaignNameDescription": "Saisissez si vous voulez que ce soit une campage ou un simple message",
 "campaignReady": "Campaign prête",
 "campaigns": "campagnes",
 "cancel": "Annuler",
 "content": "contenu",
 "created": "Nombre total de messages",
 "confirmPassword": "Confirmer le mot de passe",
 "createdAt": "Créé le",
 "createdAtDate": "Crée le {date}",
 "credit": "crédit",
 "credits": "Crédits",
 "creditsNoneLeftAccount": "Il ne vous reste aucun crédit",
 "creditsAmounts": "{numb} crédits",
 "creditsLeftAccount": "Il vous reste {count} crédits",
 "creditConsumptionLimitMonthly": "Limite de crédits (mensuel)",
 "currentMonth": "Mois en cours",
 "custom": "Personnalisé",
 "domain": "Domaine",
 "domainAlreadyExist": "Ce domaine existe déjà",
 "domainSuccessfullySent": "Nouveau domaine ajouté à la liste. N'oubliez pas de sauvegarder vos changements.",
 "details": "details",
 "deleteEntity": "Supprimer {entity}",
 "deleteMessage": "Êtes-vous sûr de vouloir supprimer cet élément ?",
 "defaultCountry": "Pays par défaut pour les numéros nationaux",
 "deactivate": "désactiver",
 "delete": "Supprimer",
 "deleteAll": "Tout supprimer",
 "didCallers": "DID Callers",
 "disclaimerExceededLengthTitle": "Nombre de caractères dépassés !",
 "disclaimerExceededLengthParagraph": "Attention, vous avez dépassé le nombre de caractères autorisés. Cela correspond à plus de 3 SMS, ce qui ne peuvent être envoyés en une seule fois.",
 "dragAndDrop": "glisser-déposer",
 "edit": "Modifier",
 "editRecipientsListUsers": "Modifier la liste de destinataires",
 "editUsersAccount": "Modifier les utilisateurs du compte",
 "editaccountsusers": "Modifier les utilisateurs du compte",
 "errored": "Echoué",
 "enabledUsers": "Activé pour les utilisateurs",
 "enabledGlobally": "Activé",
 "emailSuccessfullySent": "Nouvel e-mail ajouté à la liste. N'oubliez pas de sauvegarder vos changements.",
 "emailAlreadyExist": "Cette adresse e-mail existe déjà",
 "emptyStateTitle": "Pas de {title}",
 "emptyStateSubtitle": "Pas de données disponibles dans cette rubrique.",
 "elementDeletedSuccessfully": "{element} supprimé(e) avec succès",
 "elementCreatedSuccessfully": "{element} creé(e) avec succès",
 "elementUpdatedSuccessfully": "{element} mis à jour avec succès",
 "inactive": "inactif",
 "errorMsg": "Une erreur s'est produite",
 "emailAddress": "Adresse e-mail",
 "editrecipientslistusers": "Détail de liste de destinataires",
 "errorAccountId": "Veuillez fournir une compte",
 "errorRecipientsLists": "Veuillez fournir une liste de destinataires ou un destinataire",
 "expeditorName": "Nom de l'expéditeur",
 "expeditorNameDescription": "Le nom affiché sur le téléphone du destinataire lorsqu'il reçoit des SMS de ce compte",
 "failed": "Echoué",
 "forgotPassword": "Mot de passe oublié",
 "globalInformation": "Information générale",
 "general": "Général",
 "goodAfternoon": "Bonjour",
 "goodEvening": "Bonsoir",
 "goodMorning": "Bonjour",
 "completed": "Terminée",
 "inProgress": "En cours",
 "inputErrorInvalidEmail": "Veuillez fournir un e-mail valide",
 "invalidCredentials": "Adresse e-mail ou mot de passe incorrect",
 "lastUpdate": "Dernière modification le",
 "lastUpdateDate": "Dernière modification le {date}",
 "lastMonth": "Mois précédent",
 "lastWeek": "7 derniers jours",
 "list": "liste",
 "listsName": "nom de liste",
 "mail2smsFeature": "La fonctionnalité <span class='font-medium'>Mail to SMS</span> vous permet d'envoyer des SMS directement depuis votre boîte e-mail. Pour commencer, assurez-vous d'activer la fonctionnalité ci-dessous et d'envoyer vos e-mails depuis une adresse reconnue.<br>Envoyez ensuite vos e-mails à l'adresse <span class='font-semibold'>[numero]{'@'}sms.encom.tel</span>. Pour envoyer à l'international, assurez-vous de préfixer vos numéros par deux zéros suivis de l'indicatif pays (par exemple, <span class='font-semibold'>0033612345678{'@'}sms.encom.tel</span>).<br><span class='font-semibold'>N'incluez pas de signature</span>.",
 "mail2smsEnabled": "Activer la fonctionnalité Mail to SMS",
 "mail2smsAccountUsers": "Les utilisateurs enregistrés sur l'application sont par défaut autorisés à utiliser la fonctionnalité",
 "mail2smsAllowEmails": "Autoriser des adresses spécifiques",
 "mail2smsAllowDomains": "Autoriser des domaines",
 "marketing": "marketing",
 "manage_users": "Gérer les utilisateurs",
 "manage_lists": "Gérer les listes",
 "marketingDisclaimerPart": "Lorsque le message relève d'une communication marketing, un texte <span class='font-bold'>STOP AU 36111</span> s'ajoute automatiquement à la fin du message pour permettre à l'utilisateur de se désabonner. Pour plus d'informations sur la règlementation en rigueur, consultez le site de la CNIL.",
 "marketingDisclaimerPart1": "Attention ! Pour tous les SMS marketing, le message doit finir par",
 "marketingDisclaimerPart2": "C'est obligatoire afin de fournir à vos destinataires un moyen de se désabonner et de respecter les obligations de la CNIL.",
 "messages": "Messages",
 "messageCreatedAt": "Message(s) crée(s) le",
 "messageBeganAt": "Traitement débuté le",
 "messageSentAt": "Traitement terminé le",
 "messageTitle": "Titre du message",
 "messages_count": "Messages transactionnels",
 "name": "nom",
 "newAccount": "nouveau compte",
 "newAccountDescription": "Créez un sous-compte pour gérer l'envoi et la réception de SMS",
 "newCampaign": "nouvelle campagne",
 "newList": "Nouvelle liste",
 "newMessage": "Nouveau SMS",
 "newNumbers": "Nouveau numéros",
 "newNumbersInformationDescription": "Vous pouvez aussi ajouter des nouveaux numéros, séparés par des virgules",
 "newOrganisation": "Nouvel organisme",
 "newOrganisationDescription": "Ajoutez un organisme qui sera en mesure de créer des sous-comptes pour gérer leur propres clients",
 "newPassword": "Nouveau mot de passe",
 "newPasswordCondition": "Choisissez un nouveau mot de passe :",
 "newPasswordDescription": "Le mot de passe doit contenir au moins une majuscule, une minuscule et un chiffre.",
 "newRecipient": "Nouveau destinataire",
 "no": "non",
 "none": "Aucun",
 "noItems": "Aucuns {itemType}",
 "noItemsDetail": "Pas de données disponibles dans cette rubrique.",
 "noDataFoundUsers": "Aucun utilisateurs",
 "noDataFoundUsersDescription": "Il n'y a aucun utilisateurs enregistrés sur ce compte",
 "noAccountUsersDescription": "Veuillez sélectionner un compte dans la section à gauche de l'écran",
 "noRecipientYet": "Pas encore de destinataires ?",
 "noResults": "Aucun résultats",
 "noResultsForFilter": "Aucun résultats correspondant aux critères de recherche",
 "notMarketing": "Non marketing",
 "notStarted": "non démarrer",
 "number": "numéro",
 "numberOfAbandonedCalls": "number of abandoned calls",
 "numberOfAnsweredCalls": "number of answered calls",
 "numberOfCalls": "number of calls",
 "numberOfCallsAnsweredByAnother": "number of calls answered by another",
 "numberOfCallsDeterred": "number of calls deterred",
 "numberOfCallsTransferredByAgent": "number of calls transferred by agent",
 "numberOfRepeatedCalls": "number of repeated calls",
 "organisation": "organisme",
 "organisations": "Organismes",
 "organisationInformation": "Informations sur votre organisme",
 "organisationInformationDescription": "Organisme auquel vous êtes lié et les détails à ce sujet",
 "organisationRelated": "organisme lié",
 "otherInformation": "Autres informations",
 "otherInformationDescription": "Vous pouvez ajouter ce destinataire à une liste",
 "ownedBy": "Détenu par",
 "ownerEmail": "Email du propriétaire",
 "planned": "Planifié",
 "partialRecipientsList": "Liste non exhaustive de vos destinataires",
 "password": "Mot de passe",
 "passwordMustMatch": "Les mots de passe doivent être identique",
 "passwordLength": "Le mot de passe doit comporter au moins 12 caractères",
 "passwordResetMustContain12Characters": "Le nouveau mot de passe doit contenir au moins 12 caractères",
 "passwordResetMustContainOneNumber": "Le nouveau mot de passe doit contenir au moins un nombre",
 "passwordResetMustContainOneSpecialCharacter": "Le nouveau mot de passe doit contenir au moins un spécial caractère",
 "passwordResetMustContainOneUppercase": "Le nouveau mot de passe doit contenir au moins une majuscule",
 "pending": "En attente",
 "permissionToEdit": "Modifier",
 "permissionToEditDescription": "Ajouter à l'utilisateur la permission de modifier",
 "permissionToManageLists": "Gérer la liste des destinataires",
 "permissionToManageListsDescription": "Ajouter à l'utilisateur la permission de gérer la liste des destinataires",
 "permissionToManageUsers": "Gérer les utilisateurs",
 "permissionToManageUsersDescription": "Ajouter à l'utilisateur la permission de gérer les utilisateurs",
 "phoneNumber": "Numéro de téléphone",
 "queueDetail": "queue detail",
 "ready": "prêt",
 "role": "rôle",
 "received": "Reçu",
 "recipientsCount": "{numb} utilisateurs",
 "recipient": "Destinataire",
 "recipientInformation": "Informations sur le destinataire",
 "recipientInformationDescription": "Ajoutez ici les destinataires de votre SMS. Vous pouvez également combiner cette fonction avec une liste de destinataires",
 "recipientInformationNewDescription": "Ajoutez les informations du nouveau destinataire ici",
 "recipientListInformationNewDescription": "Ajoutez les informations de la nouvelle liste ici",
 "recipients": "Destinataires",
 "recipientsList": "Liste de destinataires",
 "recipientsLists": "Listes de destinataires",
 "recipientslistdetail": "Détail de liste de destinataires",
 "recipientsListDetailDescription": "Ci-dessous la liste des contacts présents dans la liste <span class='font-semibold text-gray-700'>{name}</span>.",
 "recipientsListsInformation": "Informations sur les destinataires",
 "recipientsListsInformationDescription": "Choisissez entre une liste de destinataire et/ou plusieurs destinataires",
 "remove": "Supprimer",
 "removeRecipients": "Retirer destinataire | Retirer destinataires",
 "removeRecipientFromList": "Retirer l'utilisateur de la liste | Retirer les destinataires de la liste",
 "removeRecipientFromListMessage": "Êtes-vous sûr de vouloir retirer ce destinataire de cette liste ? Vous pourrez l'ajouter à nouveau plus tard. | Êtes-vous sûr de vouloir retirer ces destinataires de cette liste ? Vous pourrez les ajouter à nouveau plus tard.",
 "removeUserFromAccount": "Retirer l'utilisateur du compte | Retirer les utilisateurs du compte",
 "removeUserFromAccountMessage": "Êtes-vous sûr de vouloir retirer cet utilisateur du compte ? Vous pourrez l'ajouter à nouveau plus tard. | Êtes-vous sûr de vouloir retirer ces utilisateurs du compte ? Vous pourrez les ajouter à nouveau plus tard.",
 "removeUsers": "Retirer utilisateur | Retirer utilisateurs",
 "removeUsersToAccountDescription": "Choisissez un ou plusieurs utilisateurs ci-dessous si vous souhaitez les supprimer du compte",
 "resetYourPassword": "Réinitialiser votre mot de passe",
 "defineYourPassword": "Définissez votre mot de passe",
 "resetCreatePasswordDetail": "Si un compte existe pour cet e-mail, un lien de réinitialisation de mot de passe vous sera envoyé.",
 "resetLinkSent": "Lien envoyé avec succès",
 "save": "Sauvegarder",
 "send": "Envoyer",
 "sending": "En cours",
 "sendYourFeedbackDescription": "Toutes vos remarques et suggestions nous aident à améliorer votre espace de travail.",
 "single_messages.rate": "Taux de réussite",
 "signin": "Se connecter",
 "signinToAccount": "Connectez-vous à votre compte",
 "smsLimit": "{charNumber} caractères pour {smsNumber} SMS",
 "smsMessageDescription": "Saisissez le contenu du SMS",
 "smsType": "Type de SMS",
 "smsTypeDescription": "Sélectionnez le type de SMS",
 "statistics": "Statistiques",
 "statisticsData": "données statistiques",
 "succeeded": "Succès",
 "successMsg": "Action réussie",
 "subject": "Sujet",
 "thankYouForYourFeedback": "Merci pour votre avis.",
 "timeline": "Chronologie",
 "total": "Total",
 "totalCampaign": "Totale de campagne",
 "typeYourMessageHere": "Tapez votre message ici...",
 "unreceived": "Non reçu",
 "upload": "Importer",
 "uploadAFile": "Importer un fichier",
 "updateAccountUsers": "Mettre à jour les utilisateurs du compte",
 "updateAccount": "Modifier le compte",
 "updateRecipient": "Modifier le destinataire",
 "updateList": "Modifier la liste",
 "updateCampaign": "Modifier la campagne de SMS",
 "updatedDate": "Dernière mise à jour",
 "updatedOrganisation": "Organisme mis à jour!",
 "updateOrganisation": "Modifier l'organisme",
 "updateUser": "Modifier l'utilisateur",
 "updateUserDescription": "Entrez les nouvelles informations ci-dessous",
 "user": "utilisateur",
 "users": "Utilisateurs",
 "usersList": "Liste des utilisateurs du compte {accountName}. Vous pouvez mettre à jour les utilisateurs du compte ici.",
 "viewAll": "Voir tout",
 "viewLess": "Voir moins",
 "viewMoreRecipients": "Voir plus de destinataires",
 "valueOutOfFive": "{numb} sur 5",
 "yes": "oui",
 "yourMessage": "Votre message",
 "yourFeedback": "Aidez-nous à améliorer votre service SMS !",
 "owner": "Gérant",
 "export": "exporter",
 "import": "importer",
 "importDocument": "importer un document",
 "importColumnsAutorized": "Les colonnes {columns} sont autorisées",
 "login": {
  "welcome": "Bienvenue",
  "newPassword": "Créer un mot de passe",
  "forgotPassword": "Mot de passe oublié",
  "emailAddress": "Adresse e-mail",
  "password": "Mot de passe",
  "confirmPassword": "Confirmer le mot de passe",
  "passwordCreated": "Votre mot de passe a été créé avec succès",
  "signin": "Se connecter"
 },
 "expertStatisticsNavigation": {
  "advancedStatistics": "Statistiques détaillées",
  "origins": "origines",
  "originsTop10": "Origines Top 10",
  "hourlyStatistics": "Statistiques horaire",
  "groups": "Groupe",
  "answeredUnansweredCalls": "répondus & non répondus"
 },
 "pageNotFound": "Désolé, nous n'avons pas trouvé la page que vous recherchez...",
 "goBackHome": "Page d'accueil",
 "sidebar": {
  "statistics": "Tableaux de Bord",
  "invoices": "Factures",
  "tickets": "Tickets",
  "settings": "Paramètres",
  "subMenu": { "financial": "Finances", "callQueue": "File d'appels" },
  "specialNavigation": {
   "expertService3CX": "Services Experts 3CX",
   "contact": "Routages Intelligents",
   "georouting": "Routages Géographiques",
   "callqueuegroup": "Groupes de files d'appels",
   "callqueue": "Files d'appels"
  }
 },
 "header": {
  "search": "Rechercher",
  "searchTicketPlaceHolder": "Sujets, dates de création ou dates de mise à jour",
  "searchInvoicePlaceHolder": "Factures, dates ou totaux",
  "searchUserOrNumber": "Nom de destinataire, numéro de téléphone",
  "hello": "Salut",
  "activeAccount": "Compte Actif",
  "accountName": "Nom du Compte",
  "account": "Compte",
  "invoices": "Factures",
  "impersonate": "Mode Imitation",
  "leaveImpersonate": "Quitter Mode Imitation",
  "openTicket": "Nouveau Ticket",
  "yourProfile": "Votre profil",
  "yourFeedback": "Donnez votre avis",
  "feedback": "Avis",
  "settings": "Paramètres",
  "logout": "Déconnexion",
  "filter": "Filtrer par statut"
 },
 "notificationBanner": {
  "title": "Votre application a de nouvelles fonctionnalités ! Venez en découvrir plus.",
  "learnMoreButton": "En savoir plus"
 },
 "impersonate": {
  "popupTitle": "Selectionner l'adresse e-mail d'un Utilisateur pour passer en mode Imitation"
 },
 "chartTable": {
  "callType": "Destination",
  "count": "Nombre d'appels",
  "duration": "Durée",
  "avgPerCall": "Durée moyenne",
  "landline": "Ligne fixe",
  "mobile": "Mobile",
  "special": "Spéciale",
  "callCount": "Nombre d'appels",
  "callDuration": "Durée d'appels",
  "fileNumb": "Numéro de document",
  "period": "Période",
  "choosePeriod": "Choisir la période",
  "chooseResource": "Choisir la ressource",
  "resourceNumb": "Numéro de ressource",
  "chart": "Graphiques",
  "data": {
   "incoming": "Entrant",
   "outgoing": "Sortant",
   "answered": "Répondu",
   "unanswered": "Non Répondu"
  }
 },
 "dashboards": {
  "title": "Tableau de bord",
  "day": "jour",
  "fullDay": "Journée entière",
  "week": "Semaine",
  "month": "Mois",
  "concatenate": "Consolider les données",
  "concatenateTitle": "Données consolidées pour les éléments",
  "chartHeaderTitle": "Statistiques pour la période",
  "searchExtension": "Rechercher extensions",
  "searchQueue": "Rechercher file d'appels",
  "custom": "Personnalisée",
  "hourly": "Horaire",
  "hourlyTitle": "Tableau de Bord par heure de ",
  "hourlyDescription": "Afficher les données horaires de",
  "daily": "Journalier",
  "dailyTitle": "Tableau de Bord Journalier de",
  "dailyDescription": "Afficher les données journalières de",
  "weekly": "Hebdomadaire",
  "monthly": "Mensuel",
  "monthlyTitle": "Tableau de Bord Mensuel de",
  "monthlyDescription": "Afficher les données mensuelles de",
  "donutTitle": "Nombre d'appels quotidien répondu et non répondu",
  "stackedColumnCategories": {
   "incomingCount": "Nombre d'appels entrant",
   "outgoingCount": "Nombre d'appels sortant",
   "answeredCount": "Nombre d'appels répondu",
   "unansweredCount": "Nombre d'appels non répondu",
   "incomingDuration": "Durée d'appels Rentrant",
   "outgoingDuration": "Durée d'appels Sortant",
   "answeredDuration": "Durée d'appels Répondu"
  },
  "selectAll": "Tout sélectionner",
  "clearAll": "Tout désélectionner",
  "clear": "réinitialiser",
  "error": {
   "noDataFoundTitle": "Aucune donnée trouvée",
   "noDataFoundDescription": "Les données pour cette période n'ont pas été trouvées ou n'existent pas encore, nous sommes désolés pour ce désagrément."
  }
 },
 "warning": {
  "noDataFoundTitle": "Aucune donnée trouvée",
  "noResultFoundTitle": "Aucun resultat trouvé",
  "noResultFoundDescription": "Essayez d'affiner votre recherche ou filtrer autrement.",
  "noDataFoundDocumentTitle": "Aucun Documents",
  "noDataFoundDocumentDescription": "Vous ne disposez d'aucun document. Contactez votre supérieur hiérarchique si cela n'est pas censé se produire.",
  "noDataFoundContactTitle": "Aucun Contacts",
  "noDataFoundContactDescription": "Commencez par créer un nouveau contact ou importez une liste de contact.",
  "noDataFoundCtiTitle": "Aucun CTI",
  "noDataFoundCtiDescription": "Commencez par créer une nouvelle configuration CTI.",
  "noDataFoundRoutingTitle": "Aucune Routages Géographiques",
  "noDataFoundRoutingDescription": "Commencez par créer un nouveau routages géographiques.",
  "noDataFoundCallQueueTitle": "Aucune file d'appels",
  "noDataFoundCallQueueDescription": "Commencez par créer une nouvelle file d'appels.",
  "noDataFoundCallQueueGroupTitle": "Aucun groupe de file d'appels",
  "noDataFoundCallQueueGroupDescription": "Commencez par créer un nouveau groupe de file d'appels.",
  "noDataFoundTimeSlotDescription": "Aucun créneau horaire n'a encore été créé. Veuillez en ajouter un en cliquant sur le bouton 'Nouvelle Plage Horaire'.",
  "noDataFoundExceptionDescription": "Aucun jours exceptionnels n'a encore été créé. Veuillez en ajouter un en cliquant sur l'un des boutons 'Nouveau Jour Exceptionnel'.",
  "noDataFoundSMSListTitle": "Aucune listes de SMS",
  "noDataFoundSMSListDescription": "Commencez par créer une nouvelle liste de SMS.",
  "noDataFoundDashboardTitle": "Aucun tableau de bord",
  "noDataFoundElementGroup": "Commencez par créer un nouveau groupe.",
  "noDataFoundDashboardDescription": "Commencez par sélectionner des éléments et une période de temps."
 },
 "pbxExtension": {
  "selectData": "Sélectionner une données",
  "selectPeriod": "Sélectionner une période"
 },
 "home": {
  "companies": "Entreprises"
 },
 "ticket": {
  "ticketForm": "Formulaire de création de ticket",
  "typeDown": "Selectionner votre question/problème ci-dessous",
  "category": "Catégorie",
  "subCategory": "Sous-catégorie",
  "selectProblem": "Sélectionnez le problème",
  "subject": "Sujet",
  "emailSubject": "Sujet du ticket",
  "resolution": "Date de résolution estimée",
  "body": "Body",
  "typeAbove": "Rédiger votre problème ci-dessus",
  "send": "Envoyer",
  "cancel": "Annuler",
  "reply": "Réponse",
  "writeReply": "Écrivez votre réponse pour le ticket",
  "concerns": "Concerns"
 },
 "invoiceTable": {
  "invoiceId": "Facturer ID",
  "invoiceNumb": "Facture",
  "totalWithoutTax": "Montant HT",
  "totalWithTax": "Montant TTC",
  "date": "Date",
  "searchId": "Recherche ID",
  "searchNumb": "Recherche numéro",
  "searchMonth": "MM",
  "searchYear": "AAAA",
  "searchAmount": "Recherche montant"
 },
 "ticketTable": {
  "subject": "Ticket",
  "status": "Statut",
  "priority": "Priorité",
  "createdDate": "Date",
  "createdAt": "Créer le",
  "updatedDate": "Dernière mise à jour",
  "actions": "Actions",
  "reopenTicket": "Réouvrir",
  "closeTicket": "Fermer",
  "selectRows": "Sélectionner des lignes",
  "showAll": "Afficher tout",
  "previous": "Précédent",
  "next": "Suivant",
  "rows": "lignes",
  "confirmationCloseMsg": "Êtes-vous sûr de vouloir clôturer le ticket?",
  "confirmationOpenMsg": "Êtes-vous sûr de vouloir réouvrir le ticket?",
  "closeMsg": "Ticket fermé avec succès.",
  "openMsg": "Ticket ouvert avec succès.",
  "reopenMsg": "Ticket réouvert avec succès.",
  "successMsg": "Action réussie",
  "dialogTitleClose": "Clôturer le ticket",
  "dialogTitleOpen": "Réouvrir le ticket",
  "showing": "Affichage de",
  "to": "à",
  "of": "sur",
  "results": "résultats",
  "searchStatus": "Recherche status",
  "searchDate": "JJ/MM/AAAA",
  "ticketList": "Liste des tickets"
 },
 "documentTable": {
  "allDocuments": "Tous les documents",
  "yourDocuments": "Vos documents"
 },
 "callqueue": {
  "buttonTitle": "Nouvelle file d'appels",
  "buttonTitleGroup": "Nouveau groupe de files d'appels",
  "newFormTitle": "File d'appels de routages horaires",
  "newFormTitleGroup": "Groupe de file d'appels",
  "updateException": "Modifier le jour exceptionnel",
  "updateTimeSlot": "Modifier le créneau horaire",
  "updateFormTitle": "Modifier la file d'appel",
  "updateFormTitleGroup": "Modifier le groupe de file d'appels",
  "formSubTitle": "Ajouter les informations de la nouvelle file d'appel ci-dessous.",
  "formSubTitleGroup": "Ajouter les informations du nouveau groupe de file d'appels ci-dessous.",
  "subTitle": "Mettre à jour en complétant les informations ci-dessous"
 },
 "callqueueTable": {
  "name": "Nom",
  "number": "Numéro de file",
  "weekdayAndDate": "Jour de la semaine ou date",
  "weekday": "Jour de la semaine",
  "specificDate": "Date précise",
  "timeslot": "plages horaires",
  "exception": "jours exceptionnels",
  "reference": "référence",
  "addTimeSlot": "Nouvelle plage horaire",
  "addException": "Nouveau jour exceptionnel",
  "addExceptionTwelveMonths": "Ajouter les jours fériés des 12 prochains mois",
  "formTimeSlotDescription": "Veuillez sélectionner un jour ou une plage de jours suivis des créneaux horaires associés",
  "formExceptionDescription": "Veuillez ajouter une exception à la liste d'appels en sélectionnant un jour spécifique",
  "formExceptionTwelveMonthsDescription": "Voici les douze prochains jours fériés pour la France",
  "selectAllDays": "Tous les jours de la semaine",
  "inputSelectDays": "Sélectionner plusieurs jours en utilisant",
  "or": "ou",
  "day": "Jour",
  "dateSelected": "Date sélectionnée",
  "daySelected": "Jour sélectionné",
  "start": "Début",
  "end": "Fin",
  "checkStart": "Le début ne peut pas se situer dans une plage horaire existante",
  "checkEnd": "La fin ne peut pas se situer dans une plage horaire existante",
  "checkBetween": "Cette plage horaire est en conflit avec un créneau existant",
  "pickedStartBigger": "Le début ne peut pas être après ou identique à la fin",
  "newExceptionAdded": "Nouveau jour exceptionnel choisie",
  "newExceptionError": "Désolé, il y a une erreur dans la date choisie",
  "newExceptionExist": "Ce jour exceptionnel existe déjà",
  "selectExceptionError": "Sélectionnez un jour dans le calendrier avant de continuer",
  "destinationDescription": "Vous pouvez préciser une destination ici. L'API retourne une information relative à l'ouverture ou à la fermeture de la file d'appels en fonction de l'horaire. Ne précisez pas de destination ici si votre call flow appelle l'API ultérieurement pour router l'appel (routage géographique ou intelligent...)",
  "nextFrenchHolidays": "Jours fériés français sur les 12 prochains mois",
  "addFrenchHolidaysWarning": "Vous ajouterez automatiquement des exceptions pour toutes les dates spécifiées ci-dessus",
  "continue": "Souhaitez-vous continuer ?"
 },
 "weekdays": {
  "mon": "lundi",
  "tue": "mardi",
  "wed": "mercredi",
  "thu": "jeudi",
  "fri": "vendredi",
  "sat": "samedi",
  "sun": "dimanche"
 },
 "dialog": {
  "confirm": "Confirmer",
  "goBack": "Annuler"
 },
 "profile": {
  "userInfo": "Informations de L'utilisateur",
  "personalDetails": "Détails personnels",
  "fullName": "Nom et prénom",
  "apiToken": "API Token",
  "email": "Adresse e-mail",
  "activeAccount": "Compte Actif",
  "accounts": "Comptes"
 },
 "contacts": {
  "searchAccount": "Search account",
  "searchCompany": "Search company",
  "searchName": "Search name",
  "code": "Code",
  "searchNumber": "Search number",
  "contactAdded": "Contact added successfully",
  "contactDeleted": "Contact deleted successfully",
  "addContact": "ajouter une entrée",
  "uploadContact": "ajouter plusieurs entrées (fichier)",
  "companyName": "Société",
  "identificationCode": "Code d'identification du client",
  "firstName": "Prénom",
  "lastName": "Nom",
  "vip": "Client VIP",
  "onCall": "Client sous contrat d'astreinte"
 },
 "pbx": {
  "newGroup": "Nouveau Groupe",
  "extensionNewGroupForm": "Formulaire pour créer un groupe d'extension",
  "extensionFormSubtittle": "Ajouter les information du nouveau groupe ci-dessous",
  "queueNewGroupForm": "Formulaire pour créer un groupe de file d'appels",
  "queueFormSubtittle": "Ajouter les information du nouveau groupe ci-dessous",
  "more": "autres sélections...",
  "and": "et"
 },
 "pbxReport": {
  "shareTitle": "Partager ce rapport",
  "shareMessage": "Vous voulez partager ce rapport avec les paramètres suivants",
  "shareScheduleTitle": "Partager un rapport planifié",
  "shareScheduleMessage": "Vous voulez partager un rapport planifié avec les paramètres suivants",
  "content": "Veuillez ajouter les destinataires pour compléter l'envoi. Si aucun email n'est fourni, le rapport vous sera envoyé.",
  "inputError": "Fournissez une email valide ou une adresse ne figurant pas dans la liste ci-dessous.",
  "nicknamePlaceholder": "Nom du rapport",
  "successMsg": "Rapport envoyé avec succès"
 },
 "billingModes": {
  "prepaid": "Prépayé",
  "postpaid": "Facture fin de mois",
  "inherit": "Hérité de l'organisme"
 },
 "roles": {
  "admin": "Administrateur",
  "manager": "Gérant",
  "user": "Client"
 },
 "byStatus": {
  "succeeded": "Envoyés avec succès",
  "pending": "En attente",
  "failed": "Echoués"
 },
 "passwords": {
  "user": "Adresse e-mail inconnue. Veuillez vérifier l'adresse que vous avez fournie et réessayer.",
  "token": "Demande expirée, veuillez en générer une nouvelle.",
  "reset": "Votre mot de passe a été modifié.",
  "sent": "Des instructions de réinitialisation de mot de passe vous ont été envoyées par e-mail",
  "throttled": "Limite de demande par minutes atteinte, veuillez réessayer dans quelques instants."
 }
}
